import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, setStep } from "../../store/store"
import GoBack from "../common/GoBack"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./Terms.scss"

const TermsPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(4))
	}, [])

	const onSubmit = () => {
		navigate("/refer")
	}

	const onGoBack = () => {
		navigate("/ssn")
	}

	return (
		<div className="Terms">
			<EbbNav />
			<div className="Terms_inner section">
				<GoBack onClick={onGoBack} />
				<div className="Terms_container">
					<div className="Terms_container_info">
						<h1>{t("accept-terms")}</h1>
						<p>{t("accept-terms-info")}</p>
					</div>
					<div className="Terms_container_form">
						<div className="Terms_container_form_fields">
							<p>{t("terms-agree")}</p>
						</div>
						<div className="Terms_submit">
							<NextButton text={t("form-accept")} onClick={onSubmit} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TermsPage
