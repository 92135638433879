import config from "../config"

import * as moment from "moment"
import { Moment } from "moment"
import {
	AddressFeature,
	GeoFeature,
	GeocodePointOfInterestRequestBody,
	PointOfInterestFeatureProperties,
	isMultiUnitBuilding,
} from "./flume"

//////////////////////////////////////////////////////////////////////////////
// API types.
//////////////////////////////////////////////////////////////////////////////

export type SuccessErrorResponse<T> = {
	ok: boolean
	error: string
	validationErrors: { [key: string]: string } | null
	data: T
}

export type APICard = {
	paymentMethodToken: string
	last4: string
	expire: string
}

export type APISignup = {
	language: string
	location: {
		id: string
		address: string
		floor: number
		apt: string
	}
	planID: string | null
	useEBB: boolean
	referralCode: string
	howHear: string
	customer: {
		firstName: string
		middleName: string
		lastName: string
		phone: string
		email: string
		skipEmail: boolean
		dobMonth: number
		dobDay: number
		dobYear: number
		last4SSN: string
		skipSSN: boolean
	}
	ebb: {
		hasSnap: boolean | undefined
		hasMedicaid: boolean | undefined
		hasSSI: boolean | undefined
		hasSchoolLunch: boolean | undefined
		hasPellGrant: boolean | undefined
		hasVeterans: boolean | undefined
		hasSubstantialLoss: boolean | undefined
		hasLowIncome: boolean | undefined
		applicationID: string | undefined
		bqp: {
			firstName: string
			lastName: string
			dobMonth: number
			dobDay: number
			dobYear: number
			last4SSN: string
		}
		meta: {
			nvNotImplemented: boolean
			eligabilityCheckID: string
			status: string
			certificationLink: string
		}
	}
	skipReferral: boolean
	skipCard: boolean
	card: APICard
	allowSMS: boolean
	allowMarketing: boolean
}

export type acpPlan = {
	acpPlan: null
	bandwidthMbps: number
	displayName: string
	id: string
	price: number
	children?: JSX.Element | JSX.Element[]
	priceCharged: number
}
export type Plan = {
	id: string
	displayName: string
	bandwidthMbps: number
	price: number
	priceCharged: number
	acpPlan: acpPlan
}
export type LookupAddressData = {
	address: string
	locationID: string
	isLowIncome: boolean
	enableSignups: boolean
	isBulk: boolean
	isBulkGreenfield: boolean
	numFloors: number
	plans: Plan[]
	market: string
	allowReferrals: boolean
	askForFloor: boolean
	askForUnit: boolean
	isComingSoon: boolean
}
export type LookupAddressResponse = SuccessErrorResponse<LookupAddressData>

// POST /api/create_survey
export type CreateSurveyRequest = {
	communityName: string
	communityPresidentName: string
	communityPresidentPhone: string
	communityPresidentEmail: string
	rentOrOwn: string
	firstName: string
	lastName: string
	email: string
	phone: string
	address: string
	floor: number
	unit: string
	role: number
	allowSMS: boolean
	allowMarketing: boolean
}
export type CreateSurveyResponse = SuccessErrorResponse<undefined>

// POST /api/finish_signup
export type FinishSignupRequest = {
	signupID: string
	stripePaymentMethodToken: string | null
	cardLast4: string | null
	cardExpire: string | null
}
type FinishSignupData = {
	subscriberID: string
	authToken: string
	installID: string
	installDate: {
		rangeStart: Moment | null
		rangeEnd: Moment | null
	}
}
export type FinishSignupResponse = SuccessErrorResponse<FinishSignupData>

// POST /api/update_install_date
export type UpdateInstallDateRequest = {
	subscriberID: string
	authToken: string
	installID: string
	newStart: string
	newEnd: string
}
export type UpdateInstallDateResponse = SuccessErrorResponse<undefined>

// POST /api/get_install_slots
export type GetInstallSlotsRequest = {
	locationID: string
	startDatetime: string
	endDatetime: string
}
export type GetInstallSlotsData = {
	installSlots: {
		rangeStart: string
		rangeEnd: string
	}[]
}
export type GetInstallSlotsResponse = SuccessErrorResponse<GetInstallSlotsData>

// POST /api/get_signup
export type GetSignupRequest = {
	id: string
}
export type GetSignupRequestData = {
	id: string
	state: APISignup
}

export type GetSignupResponse = SuccessErrorResponse<GetSignupRequestData>

// POST /api/save_signup
export type SaveSignupRequest = {
	id: string
	state: APISignup
}
export type SaveSignupRequestData = {
	id: string
}
export type SaveSignupResponse = SuccessErrorResponse<SaveSignupRequestData>

// POST /api/submit_signup_nv
export type SubmitSignupNVRequest = {
	id: string
}
export type SubmitSignupNVData = {
	nvNotImplemented: boolean
	eligabilityCheckID: string
	applicationID: string
	status: string
	certificationLink: string
}
export type SubmitSignupNVResponse = SuccessErrorResponse<SubmitSignupNVData>

export type GetGreenfieldUnitsRequest = {
	locationID: string
}

export type GetGreenfieldUnitsData = {
	floors: { [floor: string]: { unit: string }[] }
}
export type GetGreenfieldUnitsResponse = SuccessErrorResponse<GetGreenfieldUnitsData>

//////////////////////////////////////////////////////////////////////////////
// API interface.
//////////////////////////////////////////////////////////////////////////////
export interface API {
	// Unauthenticated
	finishSignup(req: FinishSignupRequest): Promise<FinishSignupResponse>

	createSurvey(req: CreateSurveyRequest): Promise<CreateSurveyResponse>

	lookupAddress(req: GeocodePointOfInterestRequestBody): Promise<LookupAddressResponse>

	getSignup(req: GetSignupRequest): Promise<GetSignupResponse>

	saveSignup(req: SaveSignupRequest): Promise<SaveSignupResponse>

	submitSignupNV(req: SubmitSignupNVRequest): Promise<SubmitSignupNVResponse>

	// Subscriber authenticated
	getInstallSlots(token: string, req: GetInstallSlotsRequest): Promise<GetInstallSlotsResponse>

	updateInstallDate(token: string, req: UpdateInstallDateRequest): Promise<UpdateInstallDateResponse>

	getGreenfieldUnits(req: GetGreenfieldUnitsRequest): Promise<GetGreenfieldUnitsResponse>
}

//////////////////////////////////////////////////////////////////////////////
// Real API.
//////////////////////////////////////////////////////////////////////////////
export class HTTPAPI implements API {
	_url(path: string, params?: { [key: string]: any }): string {
		const u = new URL(path, `${config.apiScheme}://${config.apiHost}`)
		if (params) {
			for (const key of Object.keys(params)) {
				u.searchParams.append(key, params[key])
			}
		}
		return u.toString()
	}

	_getJson(path: string, params?: { [key: string]: any }): Promise<any> {
		return fetch(this._url(path, params)).then((res) => {
			return res.json()
		})
	}

	_postJson(path: string, body: any, headers?: { [key: string]: string }): Promise<any> {
		const h: { [key: string]: string } = {
			"Content-Type": "application/json",
		}
		if (headers) {
			for (const key of Object.keys(headers)) {
				h[key] = headers[key]
			}
		}
		return fetch(this._url(path), {
			method: "POST",
			headers: h,
			body: JSON.stringify(body),
		}).then((res) => {
			return res.json()
		})
	}

	lookupAddress = async (req: GeocodePointOfInterestRequestBody): Promise<LookupAddressResponse> => {
		if (Object.keys(req).length === 0) {
			return {
				ok: false,
				data: null,
				error: "Address not given",
				validationErrors: null,
			} as any
		}

		const addressFeature: AddressFeature | null = await fetch("https://app.flumeinternet.com/address/geocode", {
			headers: {
				accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				placeID: req.placeID,
			}),
			method: "POST",
		})
			.then((res) => res.json())
			.catch(() => null)

		if (!addressFeature) {
			return {
				ok: false,
				data: null,
				error: "Address not found",
				validationErrors: null,
			} as any
		}

		return fetch("https://app.flumeinternet.com/point-of-interest/geocode", {
			headers: {
				accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify(req),
			method: "POST",
		})
			.then(async (res) => {
				if (!res.ok) {
					const errorSpec = await res.json()
					throw new Error(errorSpec.message)
				}

				return res.json()
			})
			.then(({ properties }: GeoFeature<any, PointOfInterestFeatureProperties>) => {
				const mdu = isMultiUnitBuilding(properties.buildingType)

				const data: LookupAddressData = {
					address: properties.addressProperties.formattedAddress || "",
					locationID: properties.pointOfInterestID,
					isLowIncome: properties.lowIncome,
					enableSignups: properties.acceptingNewSubscriptions,
					isBulk: properties.bulkDeal,
					isBulkGreenfield: properties.bulkGreenfield,
					numFloors: properties.levelCount || 1,
					plans: Object.values(properties.plansByID).map(
						(planInfo): Plan => ({
							id: planInfo.id,
							bandwidthMbps: planInfo.bandwidthMBPS,
							price: planInfo.pricePerPeriodCents,
							priceCharged: planInfo.chargedViaStripe,
							displayName: planInfo.displayName,
							acpPlan: {
								displayName: planInfo.displayName,
								id: planInfo.id,
								price: planInfo.pricePerPeriodCents,
								priceCharged: planInfo.chargedViaACP,
								acpPlan: null,
								bandwidthMbps: planInfo.bandwidthMBPS,
							},
						})
					),
					market: properties.marketInfo?.slug || "",
					allowReferrals: properties.acceptingReferrals,
					askForFloor: mdu,
					askForUnit: mdu,
					isComingSoon: properties.comingSoon,
				}

				return {
					ok: true,
					data,
					error: "",
					validationErrors: null,
				}
			})
			.catch(() => {
				const data: LookupAddressData = {
					address: addressFeature.properties.formattedAddress || "",
					locationID: "",
					isLowIncome: false,
					enableSignups: false,
					isBulk: false,
					isBulkGreenfield: false,
					numFloors: 1,
					plans: [],
					market: "",
					allowReferrals: false,
					askForFloor: false,
					askForUnit: false,
					isComingSoon: false,
				}

				return {
					ok: true,
					data,
					error: "",
					validationErrors: null,
				}
			})
	}

	getSignup(req: GetSignupRequest): Promise<GetSignupResponse> {
		return this._postJson("/api/get_signup", req).then((json) => {
			return json as GetSignupResponse
		})
	}

	saveSignup(req: SaveSignupRequest): Promise<SaveSignupResponse> {
		return this._postJson("/api/save_signup", req).then((json) => {
			return json as SaveSignupResponse
		})
	}

	submitSignupNV(req: SubmitSignupNVRequest): Promise<SubmitSignupNVResponse> {
		return this._postJson("/api/submit_signup_nv", req).then((json) => {
			return json as SubmitSignupNVResponse
		})
	}

	createSurvey(req: CreateSurveyRequest): Promise<CreateSurveyResponse> {
		return this._postJson("/api/create_survey", req).then((json) => {
			return json as CreateSurveyResponse
		})
	}

	finishSignup(req: FinishSignupRequest): Promise<FinishSignupResponse> {
		return this._postJson("/api/finish_signup", req)
			.then((json) => {
				return json as FinishSignupResponse
			})
			.catch((err) => err)
	}

	updateInstallDate(token: string, req: UpdateInstallDateRequest): Promise<UpdateInstallDateResponse> {
		return this._postJson("/api/update_install_date", req, {
			Authorization: token,
		}).then((json) => {
			return json as UpdateInstallDateResponse
		})
	}

	getInstallSlots(token: string, req: GetInstallSlotsRequest): Promise<GetInstallSlotsResponse> {
		return this._postJson("/api/get_install_slots", req, {
			Authorization: token,
		}).then((json) => {
			return json as GetInstallSlotsResponse
		})
	}

	getGreenfieldUnits(req: GetGreenfieldUnitsRequest): Promise<GetGreenfieldUnitsResponse> {
		return this._postJson("/api/get_greenfield_units", req).then((json) => {
			return json as GetGreenfieldUnitsResponse
		})
	}
}

//////////////////////////////////////////////////////////////////////////////
// Fake API.
// TODO make FakeAPI more useful (mock data file)
//////////////////////////////////////////////////////////////////////////////
export class FakeAPI implements API {
	fakeSignupId: number

	constructor() {
		this.fakeSignupId = 0
		// eslint-disable-next-line no-constant-condition
		while (true) {
			if (localStorage.getItem("flume:fakeSignup:" + String(this.fakeSignupId + 1))) {
				this.fakeSignupId++
			} else {
				break
			}
		}
	}

	lookupAddress(req: GeocodePointOfInterestRequestBody): Promise<LookupAddressResponse> {
		const DELAY_MS = 500
		return new Promise<LookupAddressResponse>((resolve, reject) => {
			setTimeout(() => {
				resolve({
					ok: true,
					error: "",
					validationErrors: {},
					data: {
						address: "2528 S Grand Ave, Los Angeles, CA 90007",
						locationID: "foo2",
						isLowIncome: false,
						enableSignups: true,
						isBulk: false,
						isBulkGreenfield: false,
						numFloors: 10,
						askForFloor: true,
						askForUnit: true,
						isComingSoon: false,
						plans: [
							{
								id: "foo2",
								bandwidthMbps: 1000,
								price: 0,
								priceCharged: 0,
								displayName: "Flume 1000",
								acpPlan: {
									displayName: "Flume 1000",
									acpPlan: null,
									bandwidthMbps: 1000,
									id: "foo",
									price: 0,
									priceCharged: 0,
								},
							},
						],
						market: "la",
						allowReferrals: false,
					},
				})
			}, DELAY_MS)
		})
	}

	getSignup(req: GetSignupRequest): Promise<GetSignupResponse> {
		const ls = localStorage.getItem("flume:fakeSignup:" + req.id)
		if (!ls) {
			return Promise.reject()
		}
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: null,
			data: {
				id: req.id,
				state: JSON.parse(ls),
			},
		})
	}

	saveSignup(req: SaveSignupRequest): Promise<SaveSignupResponse> {
		console.log("saveSignup", req)
		let id = req.id
		if (!id) {
			this.fakeSignupId++
			id = String(this.fakeSignupId)
		}
		localStorage.setItem("flume:fakeSignup:" + id, JSON.stringify(req.state))
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: null,
			data: {
				id,
			},
		})
	}

	submitSignupNV(req: SubmitSignupNVRequest): Promise<SubmitSignupNVResponse> {
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: null,
			data: {
				nvNotImplemented: false,
				eligabilityCheckID: "123",
				applicationID: "123",
				status: "EXISTING_LIFELINE_APP",
				certificationLink: "",
			},
		})
	}

	createSurvey(req: CreateSurveyRequest): Promise<CreateSurveyResponse> {
		console.log("createSurvey", req)
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: {},
			data: undefined,
		})
	}

	finishSignup(req: FinishSignupRequest): Promise<FinishSignupResponse> {
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: {},
			data: {
				subscriberID: "foo",
				authToken: "foo",
				installID: "foo",
				installDate: {
					rangeStart: moment(),
					rangeEnd: moment().add(90, "day"),
				},
			},
		})
	}

	updateInstallDate(token: string, req: UpdateInstallDateRequest): Promise<UpdateInstallDateResponse> {
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: {},
			data: undefined,
		})
	}

	getInstallSlots(token: string, req: GetInstallSlotsRequest): Promise<GetInstallSlotsResponse> {
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: {},
			data: {
				installSlots: [
					{
						rangeStart: moment().format(),
						rangeEnd: moment().format(),
					},
				],
			},
		})
	}

	getGreenfieldUnits(req: GetGreenfieldUnitsRequest): Promise<GetGreenfieldUnitsResponse> {
		return Promise.resolve({
			ok: true,
			error: "",
			validationErrors: {},
			data: {
				floors: {
					"3": [{ unit: "301" }, { unit: "302" }, { unit: "303" }, { unit: "304" }, { unit: "305" }],
					"4": [{ unit: "401" }, { unit: "402" }, { unit: "403" }, { unit: "404" }],
				},
			},
		})
	}
}
