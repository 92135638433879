import { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"

export interface LabelInputTextProps {
	id: string
	type: string
	label: string
	pattern?: string
	maxlength?: number
	max?: number
	min?: number
	placeholder: string
	onChange: (val: string) => void
	value?: string
	disabled?: boolean
	error?: string | null
	errRequired?: boolean
}

const LabelInputText: React.FC<LabelInputTextProps> = (props) => {
	const { t } = useTranslation()

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.onChange(e.target.value)
	}

	const error = props.errRequired ? t("err-required") : props.error ? props.error : ""

	return (
		<div className="labelinput">
			<label htmlFor={props.id}>{props.label}</label>
			<input
				type={props.type}
				id={props.id}
				max={props.max}
				min={props.min}
				pattern={props.pattern}
				maxLength={props.maxlength}
				placeholder={props.placeholder}
				onChange={onChange}
				value={props.value}
				disabled={props.disabled}
				className={error ? "error" : ""}
			/>
			{error ? <p className="error">{error}</p> : null}
		</div>
	)
}

export default LabelInputText
