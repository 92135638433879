import ChevronForward from "../../../../lib/assets/images/icons/chevron-forward.svg"
import "./NextButton.scss"

export interface NextButtonProps {
	text: string
	onClick: () => void
	// TODO implement this
	disabled?: boolean
}

const NextButton: React.FC<NextButtonProps> = (props) => {
	const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.stopPropagation()
		e.preventDefault()
		props.onClick()
	}
	return (
		<a href="#" className={props.disabled ? "disabledButton NextButton" : "NextButton Button"} onClick={onClick}>
			<span>{props.text}</span>
			<img src={ChevronForward} />
		</a>
	)
}

export default NextButton
