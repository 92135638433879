/**
 * Main.tsx is the entry point of the app. It mounts a React component into the DOM.
 */

// Load config.
import config from "./config"

// Load Sentry immediately.
// See: https://docs.sentry.io/platforms/javascript/

// External dependencies.
import { Elements as StripeElements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import pRetry from "p-retry"
import { useEffect } from "react"
import { createRoot } from "react-dom/client"
import { Provider, useStore } from "react-redux"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"

// Internal dependencies.
import "./lib/ts/translations"
import store, { initialize } from "./store/store"

// Components.
import Layout from "./components/layout/Layout"
import Activate from "./components/pages/Activate"
import Apartment from "./components/pages/Apartment"
import ApplicationID from "./components/pages/ApplicationID"
import BirthDate from "./components/pages/BirthDate"
import Error from "./components/pages/Error"
import Home from "./components/pages/Home"
import Install from "./components/pages/Install"
import NotAvailable from "./components/pages/NotAvailable"
import NotAvailableResult from "./components/pages/NotAvailableResult"
import Payment from "./components/pages/Payment"
import Personal from "./components/pages/Personal"
import Plan from "./components/pages/Plan"
import Questionnaire from "./components/pages/Questionnaire"
import ReferFriend from "./components/pages/ReferFriend"
import SSN from "./components/pages/SSN"
import Terms from "./components/pages/Terms"
import UseEBB from "./components/pages/UseEBB"

// Global styles.
import Event from "components/pages/Event"
import "normalize.css"
import "./global.scss"
import fakeMode from "./lib/ts/fake-mode"
import { API, FakeAPI, HTTPAPI } from "./store/api"

/**
 * There appears to be a bug in the Stripe library where it will sometimes fail to load during a
 * page transition. This is a workaround that retries the load a few times.
 *
 * @see {@link https://github.com/stripe/stripe-js/issues/26 | Stripe issue}
 */
const stripePromise = pRetry(() => loadStripe(config.stripeKey), {
	retries: 5,
	onFailedAttempt(error) {
		console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`)
	},
})

// Load fake mode using a URL like http://localhost:8080/?fake
// This is useful for local UI development without needing to run
// an API locally (or before the API is built).
let api: API
if (fakeMode()) {
	api = new FakeAPI()
} else {
	api = new HTTPAPI()
}

const App = () => {
	const store = useStore()
	useEffect(() => {
		// TODO not sure why the type of initialize is wrong, but it works.
		store.dispatch(initialize as any)
	}, [])

	return (
		<Router>
			<StripeElements stripe={stripePromise}>
				<Layout>
					<Routes>
						<Route path="/" element={<Home />} />

						<Route path="/not-available" element={<NotAvailableResult />} />
						<Route path="/check-address" element={<NotAvailable />} />
						<Route path="/plan" element={<Plan />} />
						<Route path="/use-ebb" element={<UseEBB />} />
						<Route path="/personal" element={<Personal />} />
						<Route path="/apartment" element={<Apartment />} />
						<Route path="/payment" element={<Payment />} />
						<Route path="/refer" element={<ReferFriend />} />
						<Route path="/applicationid" element={<ApplicationID />} />
						<Route path="/questionnaire" element={<Questionnaire />} />
						<Route path="/event" element={<Event />} />
						<Route path="/birth-date" element={<BirthDate />} />
						<Route path="/ssn" element={<SSN />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/install" element={<Install />} />
						<Route path="/error" element={<Error />} />
						<Route path="/activate" element={<Activate api={api} />} />
					</Routes>
				</Layout>
			</StripeElements>
		</Router>
	)
}

const divMain = document.getElementById("main")
const root = createRoot(divMain!)
root.render(
	<Provider store={store}>
		<App />
	</Provider>
)
