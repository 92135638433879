import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, setEBB, setStep } from "../../store/store"
import GoBack from "../common/GoBack"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./Questionnaire.scss"

const QuestionnairePage: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const location = useAppSelector((state) => state.location)
	const ebb = useAppSelector((state) => state.ebb)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(2))
	}, [])

	const setSnap = (val: boolean) => {
		dispatch(
			setEBB({
				...ebb,
				snap: val,
			})
		)
	}

	const setMedicaid = (val: boolean) => {
		dispatch(
			setEBB({
				...ebb,
				medicaid: val,
			})
		)
	}

	const setSSI = (val: boolean) => {
		dispatch(
			setEBB({
				...ebb,
				ssi: val,
			})
		)
	}

	const onBack = () => {
		navigate("/applicationid")
	}

	const onSubmit = () => {
		if (!ebb.snap && !ebb.medicaid && !ebb.ssi) {
			// TODO redirect to EBB error page
		}
		navigate("/apartment")
	}

	const isHighlighted = (yesNo: boolean, val: boolean | undefined): boolean => {
		if (yesNo && val === true) {
			return true
		}
		if (!yesNo && val === false) {
			return true
		}
		return false
	}

	const buttonClasses = (yesNo: boolean, val: boolean | undefined): string => {
		return isHighlighted(yesNo, val) ? "Button" : "Button Button_empty"
	}

	return (
		<div className="Questionnaire">
			<EbbNav />
			<div className="Questionnaire_inner section">
				<GoBack onClick={onBack} />
				<div className="Questionnaire_questions">
					<div className="question">
						<div className="question_title">
							<div className="question_title_step">1/3</div>
							<div className="question_title_title">
								<h1>Are you getting support from SNAP?</h1>
							</div>
						</div>
						<div className="question_description">
							<p>
								SNAP, or Supplimental Nutrition Program provides nutrition benefits to supplement the food budget of
								needy families so they can purcahse healthy food and move towards self-sufficiency.
							</p>
							<p>If you&apos;re not sure, choose No.</p>
						</div>
						<div className="question_choices">
							<div className={buttonClasses(true, ebb.snap)} onClick={() => setSnap(true)}>
								Yes
							</div>
							<div className={buttonClasses(false, ebb.snap)} onClick={() => setSnap(false)}>
								No
							</div>
						</div>
					</div>
					<div className="divider"></div>

					<div className="question">
						<div className="question_title">
							<div className="question_title_step">2/3</div>
							<div className="question_title_title">
								<h1>Are you getting support from Medicaid?</h1>
							</div>
						</div>
						<div className="question_description">
							<p>
								Medicaid provies health coverage to millions of Americans. Medicaid is administered by states, according
								to federal requirements. The program is funded jointly by states and the federal government.
							</p>
						</div>
						<div className="question_choices">
							<div className={buttonClasses(true, ebb.medicaid)} onClick={() => setMedicaid(true)}>
								Yes
							</div>
							<div className={buttonClasses(false, ebb.medicaid)} onClick={() => setMedicaid(false)}>
								No
							</div>
						</div>
					</div>
					<div className="divider"></div>

					<div className="question">
						<div className="question_title">
							<div className="question_title_step">3/3</div>
							<div className="question_title_title">
								<h1>Are you a beneficiary of the SSI program?</h1>
							</div>
						</div>
						<div className="question_description">
							<p>
								SSI (Supplemental Security Income) is a Federal income supplement program. It is designed to help aged,
								blind, and disabled people, who have little or no income. It provides cash to meet basic needs for food,
								clothing, and shelter.
							</p>
							<p>If you&apos;re not sure, choose No.</p>
						</div>
						<div className="question_choices">
							<div className={buttonClasses(true, ebb.ssi)} onClick={() => setSSI(true)}>
								Yes
							</div>
							<div className={buttonClasses(false, ebb.ssi)} onClick={() => setSSI(false)}>
								No
							</div>
						</div>
					</div>
					<div className="divider"></div>
				</div>
				<div className="Questionnaire_submit">
					<NextButton text={t("form-next")} onClick={onSubmit} />
				</div>
			</div>
		</div>
	)
}

export default QuestionnairePage
