import { ClickAwayListener, Tooltip } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ChevronBack from "../../../../lib/assets/images/icons/chevron-backward.svg"
import "./GoBack.scss"

export interface GoBackProps {
	onClick: () => void
	isGoBackDisabled?: boolean
}

const GoBack: React.FC<GoBackProps> = (props) => {
	const [open, setOpen] = useState(props.isGoBackDisabled)

	const handleClose = () => {
		setOpen(false)
	}

	const handleBackBtnClick = () => {
		if (props.isGoBackDisabled) {
			handleOpen()
		} else {
			handleClose()
			props.onClick()
		}
	}
	const handleOpen = () => {
		setOpen(true)
	}

	const { t } = useTranslation()
	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Tooltip
				open={open}
				disableHoverListener
				onClose={handleClose}
				onOpen={handleOpen}
				title={t("navigation-error")}
				placement="right"
			>
				<div className="GoBack" onClick={() => handleBackBtnClick()}>
					<img src={ChevronBack} />
					<p>{t("go-back")}</p>
				</div>
			</Tooltip>
		</ClickAwayListener>
	)
}

export default GoBack
