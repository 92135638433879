import { useTranslation } from "react-i18next"
import { useAppSelector } from "store/hooks"
import LabelInputText from "./LabelInputText"
import LabelSelect from "./LabelSelect"

export interface ApartmentComponentProps {
	floor: number
	unit: string
	onFloorChange: (val: string) => void
	onUnitChange: (val: string) => void
	saveSignupError?: any
	missing?: any
	fieldsRequired?: boolean
}

const ApartmentComponent: React.FC<ApartmentComponentProps> = (props) => {
	const { floor, unit, onUnitChange, onFloorChange, saveSignupError, missing, fieldsRequired } = props
	const { t } = useTranslation()
	const location = useAppSelector((state) => state.location)

	const floorOptions = () => {
		const options: string[] = [t("form-select-floor")]
		if (!location) {
			return options
		}
		for (let i = 0; i < location.numFloors; i++) {
			options.push(String(i + 1))
		}
		return options
	}

	return (
		<div className="Apartment_form">
			{location?.askForFloor ? (
				location?.numFloors === 0 ? (
					<LabelInputText
						id="apartment-floor"
						type="number"
						label={t("form-floor")}
						placeholder={t("form-floor")}
						onChange={onFloorChange}
						errRequired={fieldsRequired ? missing.floor : null}
						error={saveSignupError?.floor}
					/>
				) : (
					<LabelSelect
						id="apartment-floor"
						label={t("form-floor")}
						options={floorOptions()}
						onChange={onFloorChange}
						value={String(floor)}
						errRequired={fieldsRequired ? missing?.floor : null}
						error={saveSignupError?.floor}
					/>
				)
			) : null}
			{location?.askForUnit ? (
				<LabelInputText
					id="apartment-apt"
					type="text"
					label={t("form-apt")}
					placeholder={t("form-apt")}
					value={unit}
					errRequired={fieldsRequired ? missing?.unit : null}
					onChange={onUnitChange}
					error={saveSignupError?.unit}
				/>
			) : null}
		</div>
	)
}

export default ApartmentComponent
