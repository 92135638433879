import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Checkmark from "../../assets/images/icons/checkmark.svg"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, setStep } from "../../store/store"
import "./Home.scss"

const HomePage: React.FC = () => {
	// TODO loading and error states

	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const placeID = useAppSelector((state) => state.placeID)
	const locationLoading = useAppSelector((state) => state.locationLoading)
	const locationError = useAppSelector((state) => state.locationError)
	const location = useAppSelector((state) => state.location)

	useEffect(() => {
		console.debug({ location, locationLoading, locationError, placeID })

		if (locationLoading) return

		if (locationError) return navigateToAppHome()

		if (!location || !placeID) return navigateToAppHome()

		if (location.isBulk || !location.enableSignups) {
			navigate("/check-address")
		} else {
			navigate("/plan")
		}
	}, [location, locationError, locationLoading, navigate, placeID])

	useEffect(() => {
		dispatch(setStep(0))
	}, [dispatch])

	return (
		<>
			<div className="NotAvailable_background">
				<div className="NotAvailable_background_inner">
					<div className="NotAvailable_background_overlay">
						<div className="Home_info"></div>
					</div>
				</div>
			</div>
			<div className="Home-container">
				<div className="Home section"></div>
				<div className="Home section">
					<p className="error">{locationError ? locationError : null}</p>
					<div className="Home_plans">
						<div className="Home_plans_plan">
							<div className="Home_plans_plan_content">
								<div className="Home_plans_plan_name">Flume 200</div>
								<div className="Home_plans_plan_header">200 MB/s</div>
								<div className="Home_plans_plan_features">
									<div>
										<img src={Checkmark} />
										Unlimited Data
									</div>
									<div>
										<img src={Checkmark} />
										Free Modem &amp; Router
									</div>
									<div>
										<img src={Checkmark} />
										Free Installation
									</div>
								</div>
							</div>
						</div>
						<div className="Home_plans_plan green">
							<div className="Home_plans_plan_content">
								<div className="Home_plans_plan_name">Flume 1000</div>
								<div className="Home_plans_plan_header">1 GB/s</div>
								<div className="Home_plans_plan_features">
									<div>
										<img src={Checkmark} />
										Unlimited Data
									</div>
									<div>
										<img src={Checkmark} />
										Free Modem &amp; Router
									</div>
									<div>
										<img src={Checkmark} />
										Free Installation
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="terms-container">
						<p className="smallgrey">
							*Disclaimer: $500 will be granted once the property signs the Letter of Intent with Flume to install
							service within the community. The $500 giveaway is based on the complete landlord or community management
							information entry. If multiple entries to the same community are submitted, the entry that is timestamped
							earliest will be granted the $500. When the Flume Team contacts the landlord and/or community manager,
							Flume may reference the entrant&apos;s name to validate communication. Flume does not recommend entering
							information if the entrant does not want to be sourced. Offer expires on 12/31/2024, and entries will not
							be accepted after 11:59:59pm on 12/31/2024. Offer unavailable for entrants in Simi Valley, CA and East
							Hartford, CT.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default HomePage
