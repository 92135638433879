// This module exports one string, telling you which environment we're in:
//   - dev, if running locally
//   - tst, if running tests
//   - stg, if running the staging environment
//   - prd, if running in production
//
// We use FLUME_ENV to set the environment.
//
// When building, you can use `make build-prd` or `make build-stg`. See Makefile.

export type Env = "dev" | "tst" | "stg" | "prd"

let flumeEnv = process.env.FLUME_ENV || ""

if (["dev", "tst", "stg", "prd"].indexOf(flumeEnv) < 0) {
	console.error(`Unknown environment: "${flumeEnv}"`)
	console.error("Switching to environment `dev`")
	flumeEnv = "dev"
}

export const selectedEnv: Env = `${flumeEnv}` as Env
