import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { API, GetGreenfieldUnitsData, LookupAddressData } from "../../store/api"
import LabelInputText from "../common/LabelInputText"
import LabelPhone from "../common/LabelPhone"
import NextButton from "../common/NextButton"
import "./Activate.scss"

// TODO validation at every step :(

interface ActivatePageProps {
	api: API
}

const ActivatePage: React.FC<ActivatePageProps> = ({ api }) => {
	const [step, setStep] = useState<"location" | "contact" | "wifi" | "done">("location")
	const [locationSearch, setLocationSearch] = useState<string>("")
	const [location, setLocation] = useState<LookupAddressData | null>(null)
	const [units, setUnits] = useState<GetGreenfieldUnitsData | null>(null)
	const [selectedFloor, setSelectedFloor] = useState<string>("")
	const [selectedUnit, setSelectedUnit] = useState<string>("")
	const [firstName, setFirstName] = useState<string>("")
	const [lastName, setLastName] = useState<string>("")
	const [email, setEmail] = useState<string>("")
	const [phone, setPhone] = useState<string>("")
	const [wifiSSID, setWifiSSID] = useState<string>("")
	const [wifiPass, setWifiPass] = useState<string>("")

	// Setup Google Places autocomplete ///////////////////////////////////////
	type GooglePlacePrediction = {
		placeID: string
		address: string
	}

	const [searchTerm, setSearchTerm] = useState<string>("")
	const [locations, setLocations] = useState<GooglePlacePrediction[]>([])

	// The highlighted location is what the user has navigated to using the
	// arrow keys, but they haven't selected it yet.
	// The selected location is what the user clicked or hit Enter on.
	const [highlightedLocation, setHighlightedLocation] = useState<number | null>(null)
	const [selectedLocation, setSelectedLocation] = useState<GooglePlacePrediction | null>(null)

	const autoComplete = new google.maps.places.AutocompleteService()
	const sessionToken = new google.maps.places.AutocompleteSessionToken()
	const placesService = new google.maps.places.PlacesService(document.createElement("div"))

	const searchLocations = (search: string): Promise<GooglePlacePrediction[]> => {
		return new Promise((resolve, reject) => {
			const options = {
				input: search,
				sessionToken,
				language: "en",
			}
			autoComplete.getPlacePredictions(options, (suggestions: any[] | null) => {
				resolve(
					(suggestions || []).map((s) => {
						const l: GooglePlacePrediction = {
							address: s.description,
							placeID: s.place_id,
						}
						return l
					})
				)
			})
		})
	}

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		setLocationSearch(e.target.value)
		setHighlightedLocation(null)
		searchLocations(e.target.value)
			.then((locations) => {
				setLocations(locations)
			})
			.catch((err) => {
				// TODO error handling
				console.error(err)
			})
	}

	const onKeyDown = (e: KeyboardEvent) => {
		let highlighted = highlightedLocation
		if (highlighted === null) {
			highlighted = -1
		}
		if (e.key === "ArrowDown") {
			e.preventDefault()
			setHighlightedLocation(Math.min(highlighted + 1, locations.length - 1))
		} else if (e.key === "ArrowUp") {
			e.preventDefault()
			setHighlightedLocation(Math.max(highlighted - 1, 0))
		} else if (e.key === "Enter" && highlighted >= 0) {
			e.preventDefault()
			onSelectLocation(locations[highlighted])
		}
	}

	const onSelectLocation = (l: GooglePlacePrediction) => {
		placesService.getDetails(
			{
				placeId: l.placeID,
				sessionToken,
			},
			(results, status) => {
				if (status !== google.maps.places.PlacesServiceStatus.OK || !results) {
					// TODO errors...
					console.error("Non-OK status from Places")
					return
				}

				const placeID = results.place_id!
				const address = results.formatted_address || ""
				setLocationSearch(address)
				setLocations([])
				api
					.lookupAddress({
						placeID,
					})
					.then((res) => {
						// TODO handle errors
						if (!res.data.isBulkGreenfield) {
							const nextURL = new URL(window.location.origin)
							nextURL.searchParams.append("placeID", placeID)

							window.location.replace(nextURL)

							return
						}
						setLocation(res.data)
						setLocationSearch(res.data.address)
						return api.getGreenfieldUnits({
							locationID: res.data.locationID,
						})
					})
					.then((res) => {
						if (!res) {
							return
						}
						setUnits(res.data)
					})
			}
		)
	}

	// When a location is selected, clear search result.
	useEffect(() => {
		if (selectedLocation === null) {
			return
		}
		setLocationSearch(selectedLocation.address)
		setLocations([])
		setHighlightedLocation(null)
	}, [selectedLocation])

	// TODO ?address parameter?
	///////////////////////////////////////////////////////////////////////////

	return (
		<div className="Activate section">
			{step === "location" && (
				<div className="Activate_location">
					<div>
						<h1>Activate Your Wifi</h1>
						<p>Enter your address to begin.</p>
					</div>
					<div className="LocationSearch">
						<input
							type="text"
							value={locationSearch}
							placeholder="123 Broadway"
							onChange={onSearch}
							onKeyDown={onKeyDown}
						/>
						<div className="LocationSearch_locations_outer">
							<div className="LocationSearch_locations_inner">
								{locations.map((l, idx) => (
									<div
										className={`LocationSearch_locations_location ${highlightedLocation === idx && "highlighted"}`}
										key={l.placeID}
										onClick={() => onSelectLocation(l)}
									>
										{l.address}
									</div>
								))}
							</div>
						</div>
					</div>
					{location && units ? (
						<div className="FloorSelect">
							<select onChange={(e) => setSelectedFloor(e.target.value)}>
								<option value="" key="none">
									Select a floor
								</option>
								{Object.keys(units.floors).map((floor) => {
									return (
										<option value={floor} key={floor}>
											Floor {floor}
										</option>
									)
								})}
							</select>
						</div>
					) : null}
					{location && units && selectedFloor && !selectedUnit ? (
						<div className="UnitSelect">
							<div className="units">
								{units.floors[selectedFloor].map((unit) => {
									return (
										<div className="unit" key={unit.unit} onClick={() => setSelectedUnit(unit.unit)}>
											{unit.unit}
										</div>
									)
								})}
							</div>
						</div>
					) : null}
					{location && units && selectedFloor && selectedUnit ? (
						<div className="UnitSelect">
							<p>Unit {selectedUnit}</p>
							<NextButton text="Next" onClick={() => setStep("contact")} />
						</div>
					) : null}
				</div>
			)}
			{step === "contact" && (
				<div className="Activate_contact">
					<div className="Activate_contact_info">
						<p>Please provide your contact information so we know how to reach you.</p>
					</div>
					<LabelInputText
						id="activate-first"
						label="First Name"
						placeholder="First Name"
						value={firstName}
						type="text"
						onChange={setFirstName}
					/>
					<LabelInputText
						id="activate-last"
						label="Last Name"
						placeholder="Last Name"
						value={lastName}
						type="text"
						onChange={setLastName}
					/>
					<LabelInputText
						id="activate-email"
						label="Email"
						placeholder="Email"
						value={email}
						type="text"
						onChange={setEmail}
					/>
					<LabelPhone
						id="activate-phone"
						label="Phone"
						placeholder="(555) 555-5555"
						value={phone}
						onChange={setPhone}
					/>
					<NextButton text="Setup Wifi" onClick={() => setStep("wifi")} />
				</div>
			)}
			{step === "wifi" && (
				<div className="Activate_wifi">
					<div className="Activate_wifi_info">
						<p>Choose your Wifi network name (SSID) and password.</p>
					</div>
					<LabelInputText
						id="activate-ssid"
						label="Network Name (SSID)"
						placeholder={"flume-unit-" + selectedUnit}
						value={wifiSSID}
						type="text"
						onChange={setWifiSSID}
					/>
					<LabelInputText
						id="activate-pass"
						label="Password"
						placeholder=""
						value={wifiPass}
						type="text"
						onChange={setWifiPass}
					/>
					<NextButton text="Finish" onClick={() => setStep("done")} />
				</div>
			)}
			{step === "done" && (
				<div className="Activate_done">
					<div className="Activate_done_info">
						<p>All set! Here is your wifi info:</p>
					</div>
					<div className="Activate_done_wifiinfo">
						<p>{wifiSSID}</p>
						<p>{wifiPass}</p>
					</div>
					<div>
						<p>Please allow a few minutes for your Wifi to be activated.</p>
						<p>If you need help, please text our support at:</p>
						<p>
							<a href="tel:+18559135863">+1 (855) 913-5863</a>
						</p>
					</div>
				</div>
			)}
			<div className="divider"></div>
		</div>
	)
}

export default ActivatePage
