import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface LabelSelectProps {
	id: string
	label: string
	options: string[]
	onChange: (val: string) => void
	value?: string
	disabled?: boolean
	error?: string
	errRequired?: boolean
}

const LabelSelect: React.FC<LabelSelectProps> = (props) => {
	const { t } = useTranslation()

	const onChange = (e: SelectChangeEvent) => {
		props.onChange(e.target.value)
	}

	const dropdownStyles = {
		height: "50px",
		fontFamily: "Untitled Sans",
		backgroundColor: "#ffffff",
	}
	const error = props.errRequired ? t("err-required") : props.error ? props.error : ""

	return (
		<div className="labelinput">
			<label htmlFor={props.id}>{props.label}</label>
			<Select
				id={props.id}
				onChange={onChange}
				value={props.value}
				displayEmpty
				sx={dropdownStyles}
				disabled={props.disabled}
				className={error ? "error" : ""}
			>
				{props.options.map((opt) => (
					<MenuItem value={opt} key={opt}>
						{opt}
					</MenuItem>
				))}
			</Select>
			{error ? <p className="error">{error}</p> : null}
		</div>
	)
}

export default LabelSelect
