import Chip from "@mui/material/Chip"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, saveSignup, setPlanID, setStep, setUseEBB } from "../../store/store"
import "./UseEBB.scss"

const UseEBBPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)
	const useEBB = useAppSelector((state) => state.useEBB)
	const price = useAppSelector((state) => state.planPrice)
	const planType = useAppSelector((state) => state.planType)
	const ebbOptions = [
		{
			heading: "useebb-apply",
			info: "useebb-apply-info",
			ebb: true,
			price,
			priceCharged: price - 30,
		},
		{
			heading: "useebb-regular",
			info: "useebb-regular-info",
			ebb: false,
			priceCharged: price,
		},
	]
	const chipStyles = {
		backgroundColor: "#E2EBE2",
		fontWeight: "500",
		fontSize: "1px",
		lineHeight: "20px",
		border: "1px solid #64646D",
		fontFamily: "Untitled Sans",
	}
	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(0))
	}, [])

	const selectEBB = (useEBB: boolean) => {
		if (useEBB && location) {
			dispatch(setPlanID(location?.plans[planType]?.acpPlan.id))
		}
		dispatch(setUseEBB(useEBB))
		dispatch(saveSignup)
	}

	useEffect(() => {
		if (useEBB === undefined) {
			return
		}
		navigate("/personal")
	}, [useEBB])

	return (
		<div className="UseEBB">
			<div className="UseEBB_background"></div>
			<div className="UseEBB_options">
				{ebbOptions.map((ebbOption, index) => (
					<div key={index} className="UseEBB_options_option" onClick={() => selectEBB(ebbOption.ebb)}>
						<div className="UseEBB_options_heading-container">
							<h1 className="UseEBB_options_heading">{t(ebbOption.heading)}</h1>
							<h1>
								<Chip
									label={
										<div className="UseEBB_chip">
											{ebbOption.price ? <p className="UseEBB_chip_original-price">${ebbOption.price}</p> : null}
											<p>${ebbOption.priceCharged}/mo</p>
										</div>
									}
									sx={chipStyles}
								/>
							</h1>
						</div>
						<p>{t(ebbOption.info)}</p>
					</div>
				))}
			</div>
		</div>
	)
}

export default UseEBBPage
