import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { debounceReact } from "../../../../lib/ts/debounce"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import {
	navigateToAppHome,
	saveSignup,
	setPersonal,
	setPlanID,
	setSaveSignupLoading,
	setStep,
	setUseEBB,
} from "../../store/store"
import GoBack from "../common/GoBack"
import LabelCheckbox from "../common/LabelCheckbox"
import LabelInputText from "../common/LabelInputText"
import LabelPhone from "../common/LabelPhone"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./Personal.scss"

const PersonalPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)
	const personal = useAppSelector((state) => state.personal)
	const saveSignupLoading = useAppSelector((state) => state.saveSignupLoading)
	const useEBB = useAppSelector((state) => state.useEBB)
	const saveSignupError = useAppSelector((state) => state.saveSignupError)
	const [missing, setMissing] = useState<any>({})
	const isNextButtonDisabled = Boolean(saveSignupError)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(1))
	}, [])
	const debouncedSave = debounceReact(useState<NodeJS.Timeout>(), () => {
		dispatch(saveSignup)
	})

	const onFirstNameChange = (val: string) => {
		dispatch(
			setPersonal({
				...personal,
				firstName: val,
			})
		)
		if (personal.phone.length == 12) {
			debouncedSave()
		}
	}
	const onLastNameChange = (val: string) => {
		dispatch(
			setPersonal({
				...personal,
				lastName: val,
			})
		)
		if (personal.phone.length == 12) {
			debouncedSave()
		}
	}
	const onPhoneChange = (val: string) => {
		dispatch(setSaveSignupLoading(true))
		dispatch(
			setPersonal({
				...personal,
				phone: val,
			})
		)
		if (val.length == 12) {
			debouncedSave()
		}
	}

	const onEmailChange = (val: string) => {
		dispatch(
			setPersonal({
				...personal,
				email: val,
			})
		)
		if (personal.phone.length == 12) {
			debouncedSave()
		}
	}

	const onSMSChange = () => {
		dispatch(
			setPersonal({
				...personal,
				allowSMS: !personal.allowSMS,
			})
		)
		if (personal.phone.length == 12) {
			debouncedSave()
		}
	}

	const onMarketingChange = () => {
		dispatch(
			setPersonal({
				...personal,
				allowMarketing: !personal.allowMarketing,
			})
		)
		if (personal.phone.length == 12) {
			debouncedSave()
		}
	}

	const onSubmit = () => {
		// Validate all required fields have been filled out.
		const missing: any = {}
		if (!personal.firstName) {
			missing.firstName = true
		}
		if (!personal.lastName) {
			missing.lastName = true
		}
		if (!personal.phone || personal.phone.length < 12) {
			missing.phone = true
		}
		if (Object.keys(missing).length > 0) {
			setMissing(missing)
			return
		}
		dispatch(saveSignup)
		if (!saveSignupError) {
			if (useEBB) {
				navigate("/applicationid")
			} else {
				navigate("/apartment")
			}
		}
	}

	const onGoBack = () => {
		dispatch(setPlanID(null))
		dispatch(setUseEBB(undefined))
		if (useEBB === undefined) {
			navigate("/plan")
		} else {
			navigate("/use-ebb")
		}
	}

	return (
		<div className="Personal">
			<EbbNav />
			<div className="Personal_inner section">
				<GoBack onClick={onGoBack} isGoBackDisabled={saveSignupLoading || isNextButtonDisabled} />

				<div className="Personal_container">
					<div className="Personal_container_info">
						<h1>{t("personal-information")}</h1>
						<p>{t("personal-information-info")}</p>
					</div>
					<div className="Personal_container_form">
						<LabelInputText
							id="personal-firstName"
							label={t("form-firstname") + "*"}
							type="text"
							placeholder={t("form-firstname")}
							value={personal.firstName}
							onChange={onFirstNameChange}
							errRequired={missing.firstName}
							error={saveSignupError ? saveSignupError["customer.firstName"] : null}
						/>
						<LabelInputText
							id="personal-lastName"
							label={t("form-lastname") + "*"}
							placeholder={t("form-lastname")}
							value={personal.lastName}
							type="text"
							onChange={onLastNameChange}
							errRequired={missing.lastName}
							error={saveSignupError ? saveSignupError["customer.lastName"] : null}
						/>
						<LabelPhone
							id="personal-phone"
							label={t("form-phone") + "*"}
							placeholder="(555) 555-5555"
							value={personal.phone}
							onChange={onPhoneChange}
							errRequired={missing.phone}
							error={saveSignupError ? saveSignupError["customer.phone"] : null}
						/>
						<LabelInputText
							id="personal-email"
							label={t("form-email")}
							placeholder="you@email.com"
							value={personal.email}
							type="email"
							onChange={onEmailChange}
							error={saveSignupError ? saveSignupError["customer.email"] : null}
						/>
						<p className="smallgrey">* {t("form-required-fields")}</p>
						<LabelCheckbox
							id="na-sms"
							label="Allow Flume to send me text messages for customer support purposes."
							onChange={onSMSChange}
							defaultChecked={true}
						/>
						<LabelCheckbox
							id="na-marketing"
							label="I want to receive updates and offers from Flume."
							onChange={onMarketingChange}
							defaultChecked={true}
						/>
						<div className="Personal_submit">
							<NextButton
								text={t("form-next")}
								onClick={onSubmit}
								disabled={isNextButtonDisabled || saveSignupLoading}
							/>
						</div>
						<p className="error">{typeof saveSignupError === "string" && saveSignupError}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonalPage
