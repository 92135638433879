import { useTranslation } from "react-i18next"
import Pencil from "../../assets/images/icons/pencil.svg"
import { useAppSelector } from "../../store/hooks"
import { navigateToAppHome } from "../../store/store"
import "./EditLocation.scss"

const EditLocation: React.FC = () => {
	const { t } = useTranslation()
	const location = useAppSelector((state) => state.location)

	return (
		<div className="EditLocation" onClick={navigateToAppHome}>
			<div className="EditLocation_inner">
				<img src={Pencil} />
				<p>
					{t("plan-service-address")}
					&nbsp;
					<span>{location?.address}</span>
					&nbsp;
				</p>
			</div>
		</div>
	)
}

export default EditLocation
