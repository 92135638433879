/**
 * @file Partial types from Flume API.
 */

/**
 * The type of a building, i.e. apartments, condos, etc.
 */
export type BuildingType =
	| "Unknown"
	| "MultiDwellingUnit"
	| "MultiBusinessUnit"
	| "SingleFamilyHome"
	| "DuplexResidence"
	| "DuplexComplex"
	| "SC"
	| "HOA"
	| "Mixed"
	| "VacantLot"

/**
 * Buildings with potentially more than one unit.
 */
export type MultiUnitBuildingType = Extract<
	BuildingType,
	"MultiDwellingUnit" | "MultiBusinessUnit" | "DuplexComplex" | "DuplexResidence" | "Mixed"
>

/**
 * Predicate if the type of a building is considered to be a multi-unit building.
 *
 * This is useful for determining whether or not we need to collect a unit number.
 */
export function isMultiUnitBuilding(buildingType: BuildingType): buildingType is MultiUnitBuildingType {
	return [
		// ---
		"MultiDwellingUnit",
		"MultiBusinessUnit",
		"DuplexResidence",
		"DuplexComplex",
		"Mixed",
	].includes(buildingType)
}

/**
 * The category of a building, i.e. residential, commercial, etc.
 */
export type BuildingCategory = "unknown" | "residential" | "commercial"

/**
 * Information used to describe occupiable levels in a specific building.
 */
export interface BuildingLevel {
	/**
	 * ID of the building level.
	 *
	 * @format uuid
	 */
	id: string

	/**
	 * Parent point of interest, if any.
	 *
	 * @format uuid
	 */
	pointOfInterestID: null | string
	/**
	 * The actual physical vertical order of the level, respective to the position within a building.
	 *
	 * Integer!
	 */
	verticalOrder: number
	// TODO: Implement.
	// locationUnits $PointOfInterestSubPremise[]
	// networkNodes  $NetworkNode[]
}

/**
 * @internal
 */
export type GeoFeature<G, P extends {} = {}> = {
	type: "Feature"
	geometry: G
	properties: P
}
/**
 * Information describing a plan for Flume Internet service.
 */
export interface PlanInfo {
	id: string

	/**
	 * The plan's name.
	 */
	displayName: string

	/**
	 * The Plan's plan bandwidth in MBSP.
	 *
	 * Integer!
	 *
	 * @minimum 0
	 */
	bandwidthMBPS: number

	/**
	 * The Plan's plan price in USD cents.
	 *
	 * Integer!
	 *
	 * @minimum 0
	 */
	pricePerPeriodCents: number

	/**
	 * The amount of the `pricePerPeriodCents` that is charged to the customer's ACP benefit.
	 *
	 * @deprecated Remove once ACP is sunsetted.
	 * @minimum 0
	 */
	chargedViaACP: number

	/**
	 * The amount of the `pricePerPeriodCents` that is charged to the customer's credit card.
	 */
	chargedViaStripe: number

	stripeProductID: null | string
	stripePriceID: null | string

	ubiPlanID: null | string

	/**
	 * Associated ACP plan that is billed alongside this Plan.
	 *
	 * @format uuid
	 */
	acpPlanID: null | string
}

export interface MarketInfo {
	id: string
	slug: string
	planInfos: PlanInfo[]
}

/**
 * Describes the properties of the address feature type.
 */
export interface AddressFeatureProperties {
	/**
	 * The country portion of the address.
	 */
	country?: string

	/**
	 * The two-letter abbreviation of a US state.
	 */
	stateAbbreviation?: string

	/**
	 * The postal code portion of the address.
	 */
	postalCode?: string

	/**
	 * The city, town, village, or other locale portion of the address
	 */
	locality?: string

	/**
	 * The street's cardinality portion of the address.
	 */
	cardinalAbbreviation?: string

	/**
	 * The name portion of the road.
	 */
	streetName?: string

	/**
	 * The standard abbreviation of the street's suffix portion of the address.
	 */
	streetSuffixAbbreviation?: string

	/**
	 * The house number portion of the address.
	 */
	streetNumber?: string

	/**
	 * The first number of the address range.
	 */
	rangeFirst?: number

	/**
	 * The last number of the address range.
	 */
	rangeLast?: number

	/**
	 * A human-friendly, address-compatible string denoting the unit's label within a build
	 */
	postalDesignator?: string

	/**
	 * A human-friendly, address-compatible string denoting the location of a floor or level of the
	 * unit in a building.
	 */
	postalFloor?: string

	/**
	 * The P.O. box portion of the address.
	 */
	poBox?: string

	/**
	 * The formatted address string.
	 */
	formattedAddress?: string

	/**
	 * Reference a known point of interest.
	 *
	 * @format uuid
	 */
	pointOfInterestID?: string
	/**
	 * Reference a known point of interest subpremise.
	 *
	 * @format uuid
	 */
	pointOfInterestSubPremiseID?: string
}

/**
 * Information about a specific building or geographic location.
 */
export interface PointOfInterestFeatureProperties {
	/**
	 * The POI's ID as stored by Flume.
	 *
	 * @format uuid
	 */
	pointOfInterestID: string

	/**
	 * A sub-premise's ID as stored by Flume. Present only if the POI represents a sub-premise of
	 * another location, e.g. an apartment building.
	 *
	 * @format uuid
	 */
	pointOfInterestSubPremiseID: string | null

	/**
	 * The number of floors in the location.
	 *
	 * Integer!
	 */
	levelCount: number | null
	/**
	 * The number of units in the location.
	 *
	 * Integer!
	 */
	unitCount: number | null

	buildingLevels: BuildingLevel[]

	/**
	 * The POI's building type, e.g. single-family home, apartment building, etc.
	 *
	 * @see {@linkcode BuildingType}
	 */
	buildingType: BuildingType
	/**
	 * The POI's building category, e.g. commercial, residential, etc.
	 *
	 * @see {@linkcode BuildingCategory}
	 */
	buildingCategory: BuildingCategory

	/**
	 * Indicates whether the address qualifies for low-income EBB.
	 */
	lowIncome: boolean

	/**
	 * A record of plans available at this location.
	 */
	plansByID: Record<string, PlanInfo>

	/**
	 * Parsed address of the POI and its sub-premise, if applicable.
	 */
	addressProperties: AddressFeatureProperties
	marketInfo: MarketInfo | null
	acceptingReferrals: boolean
	bulkDeal: boolean
	bulkGreenfield: boolean
	acceptingNewSubscriptions: boolean
	comingSoon: boolean
	/**
	 * Estimated date that Flume internet service will be available.
	 *
	 * @format date-time
	 */
	estimatedAvailabilityDate: string | null
}

export type AddressFeature = GeoFeature<any, AddressFeatureProperties>

export interface GeocodeAddressRequestBody {
	/**
	 * A USPS formatted address string.
	 */
	formattedAddress?: null | string

	/**
	 * Google Places ID.
	 */
	placeID?: null | string
}

export interface GeocodePointOfInterestRequestBody extends GeocodeAddressRequestBody {
	/**
	 * A known Point of Interest ID. Useful if you just want to geocode via our database.
	 *
	 * @format uuid
	 */
	pointOfInterestID?: string | null

	/**
	 * A known Point of Interest ID subpremise. Useful if you just want to geocode via our database.
	 *
	 * Note that providing `pointOfInterestSubPremiseID` will take precedence over any parent
	 * `pointOfInterestID`.
	 *
	 * @format uuid
	 */
	pointOfInterestSubPremiseID?: string | null
}
