import { Switch } from "@mui/material"
import LabelInputText from "components/common/LabelInputText"
import NextButton from "components/common/NextButton"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, setIsEventMode } from "../../store/store"
import "./Event.scss"

const EventPage: React.FC = () => {
	const navigate = useNavigate()
	const [eventName, setEventName] = useState<string>("")
	const dispatch = useAppDispatch()
	const isEventMode = useAppSelector((state) => state.isEventMode)
	const onSubmit = () => {
		sessionStorage.setItem("event-mode", isEventMode ? "on" : "off")
		sessionStorage.setItem("event-name", eventName)

		navigateToAppHome()
	}
	const onEventNameChange = (val: string) => {
		setEventName(val)
	}
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setIsEventMode(event.target.checked))
	}
	return (
		<div className="Event">
			<div className="Event_inner section">
				<div className="Event_container">
					<div className="Event_container_info">
						<h1>Event Mode</h1>
						<p>Turn on the Event Mode from here and type the event name</p>
					</div>
					<div className="Event_container_form">
						<div className="Event_mode">
							<p>Event Mode {isEventMode ? "On" : "Off"}</p>
							<Switch color="success" checked={isEventMode} onChange={handleChange} />
						</div>
						{isEventMode ? (
							<LabelInputText
								id="event-name"
								type="text"
								label="Event Name"
								placeholder="Event Name"
								value={eventName}
								onChange={onEventNameChange}
							/>
						) : null}
						<div className="Apartment_submit">
							<NextButton text="Next" onClick={onSubmit} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EventPage
