import { Checkbox } from "@mui/material"
import { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"

export interface LabelCheckboxProps {
	id: string
	label: string
	onChange: () => void
	defaultChecked?: boolean
	disabled?: boolean
	error?: string
	errRequired?: boolean
}

const LabelCheckbox: React.FC<LabelCheckboxProps> = (props) => {
	const { t } = useTranslation()

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.onChange()
	}

	const error = props.errRequired ? t("err-required") : props.error ? props.error : ""

	return (
		<div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
			<Checkbox
				id={props.id}
				onChange={onChange}
				sx={{ flex: 0, paddingLeft: "0" }}
				disabled={props.disabled}
				className={error ? "error" : ""}
				defaultChecked={props.defaultChecked}
				disableRipple={true}
			/>
			<label htmlFor={props.id} style={{ flex: 1, paddingTop: "10px" }}>
				{props.label}
			</label>
			{error ? <p className="error">{error}</p> : null}
		</div>
	)
}

export default LabelCheckbox
