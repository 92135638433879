import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { handleSubmitSurvey, navigateToAppHome, setStep, setSurveyReq } from "../../store/store"
import EditLocation from "../common/EditLocation"
import LabelCheckbox from "../common/LabelCheckbox"
import LabelInputText from "../common/LabelInputText"
import LabelPhone from "../common/LabelPhone"
import LabelSelect from "../common/LabelSelect"
import NextButton from "../common/NextButton"
import "./NotAvailable.scss"

const NotAvailablePage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)
	const survey = useAppSelector((state) => state.surveyReq)
	const surveyLoading = useAppSelector((state) => state.surveyLoading)
	const floor = useAppSelector((state) => state.floor)
	const unit = useAppSelector((state) => state.unit)
	const surveyError = useAppSelector((state) => state.surveyError)
	const [missing, setMissing] = useState<any>({})

	// If this URL is visited directly, there won't be any location loaded.
	// Also, when the success modal is closed, location will be reset.
	// Also, if the user clicks the EditLocation widget, location will be reset.
	// In any case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(0))
	}, [])

	const onCommunityNameChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				communityName: val,
			})
		)
	}

	const onPresidentNameChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				communityPresidentName: val,
			})
		)
	}

	const onPresidentPhoneChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				communityPresidentPhone: val,
			})
		)
	}

	const onPresidentEmailChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				communityPresidentEmail: val,
			})
		)
	}

	const onRentOrOwnChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				rentOrOwn: val,
			})
		)
	}

	const onFirstNameChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				firstName: val,
			})
		)
	}

	const onLastNameChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				lastName: val,
			})
		)
	}

	const onPhoneChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				phone: val,
			})
		)
	}

	const onEmailChange = (val: string) => {
		dispatch(
			setSurveyReq({
				...survey,
				email: val,
			})
		)
	}

	const onSMSChange = () => {
		dispatch(
			setSurveyReq({
				...survey,
				allowSMS: !survey.allowSMS,
			})
		)
	}

	const onMarketingChange = () => {
		dispatch(
			setSurveyReq({
				...survey,
				allowMarketing: !survey.allowMarketing,
			})
		)
	}

	const onSubmit = () => {
		// Validate all required fields have been filled out.
		const missing: any = {}
		if (!survey.communityName) {
			missing.communityName = true
		}
		if (!survey.communityPresidentName) {
			missing.communityPresidentName = true
		}
		if (!survey.communityPresidentEmail) {
			missing.communityPresidentEmail = true
		}
		if (Object.keys(missing).length > 0) {
			setMissing(missing)
			return
		}

		// Creating the thunk function
		const submitSurvey = handleSubmitSurvey(navigate)
		// Then dispatch the thunk function itself
		dispatch(submitSurvey as any)
	}

	// TODO loading state
	// TODO error modal

	const locationID = location ? location.locationID : ""

	const offerLink = <a href="https://www.flumeinternet.com" className="Offer500" target="_blank" rel="noreferrer"></a>
	const info = location?.isBulk ? (
		t("bulk-signup-available-info")
	) : location?.isComingSoon ? (
		<h1>{t("not-available-info-coming-soon")}</h1>
	) : (
		offerLink
	)
	const title = location?.isBulk ? (
		<h1>{t("bulk-signup-available-title")}</h1>
	) : location?.isComingSoon ? (
		t("not-available-title-coming-soon")
	) : (
		<div>
			<h1>Bring Flume to your community by completing the form.</h1>
			<h1>We’ll be in touch soon!</h1>
		</div>
	)

	return (
		<div className="NotAvailable">
			<div className="NotAvailable_background">
				<div className="NotAvailable_background_inner">
					<div className="NotAvailable_background_overlay"></div>
					<EditLocation />
				</div>
			</div>
			<div className="NotAvailable_topbanner">
				<div className="NotAvailable_topbanner_inner"></div>
			</div>
			<div className="NotAvailable_form section">
				<div className="NotAvailable_form_container">
					<div className="NotAvailable_form_information">
						{title}
						{info}
					</div>
					{!location?.isBulk && (
						<div className="NotAvailable_form_personal">
							<h1>Your Community Information</h1>
							<LabelInputText
								id="na-commname"
								label="Community or Building Name*"
								placeholder="Community or Building Name"
								onChange={onCommunityNameChange}
								type="text"
								disabled={surveyLoading}
								errRequired={missing.communityName}
							/>
							<LabelInputText
								id="na-commpresidentname"
								label="Landlord or Community Board Contact (Name)*"
								placeholder="Name"
								onChange={onPresidentNameChange}
								type="text"
								disabled={surveyLoading}
								errRequired={missing.communityPresidentName}
							/>
							<LabelPhone
								id="na-commpresidentphone"
								label="Landlord or Community Board Contact (Phone)"
								placeholder="(555) 555-5555"
								onChange={onPresidentPhoneChange}
								disabled={surveyLoading}
								errRequired={missing.communityPresidentPhone}
							/>
							<LabelInputText
								id="na-commpresidentemail"
								label="Landlord or Community Board Contact (Email)*"
								placeholder="Email"
								onChange={onPresidentEmailChange}
								type="text"
								disabled={surveyLoading}
								errRequired={missing.communityPresidentEmail}
							/>
							<h1>Your Information</h1>
							<LabelInputText
								id="na-firstName"
								label={t("form-firstname")}
								placeholder={t("form-firstname")}
								onChange={onFirstNameChange}
								type="text"
								disabled={surveyLoading}
								errRequired={missing.firstName}
							/>
							<LabelInputText
								id="na-lastName"
								label={t("form-lastname")}
								placeholder={t("form-lastname")}
								onChange={onLastNameChange}
								type="text"
								disabled={surveyLoading}
								errRequired={missing.lastName}
							/>
							<LabelPhone
								id="na-phone"
								label={t("form-phone")}
								placeholder="(555) 555-5555"
								onChange={onPhoneChange}
								disabled={surveyLoading}
								errRequired={missing.phone}
							/>
							<LabelInputText
								id="na-email"
								label={t("form-email")}
								placeholder="you@email.com"
								onChange={onEmailChange}
								type="email"
								disabled={surveyLoading}
								errRequired={missing.email}
							/>
							<LabelSelect
								id="na-rentOrOwn"
								label="Do you rent or own?"
								options={["Rent", "Own"]}
								onChange={onRentOrOwnChange}
							/>
							<p className="smallgrey">* {t("form-required-fields")}</p>
							<p className="error">{surveyError ? surveyError : null}</p>
							<LabelCheckbox
								id="na-sms"
								label="Allow Flume to send me text messages for customer support purposes."
								onChange={onSMSChange}
								defaultChecked={true}
							/>
							<LabelCheckbox
								id="na-marketing"
								label="I want to receive updates and offers from Flume."
								onChange={onMarketingChange}
								defaultChecked={true}
							/>
						</div>
					)}
				</div>
				{!location?.isBulk && (
					<div className="NotAvailable_form_submit">
						<p>
							<NextButton text="Submit" onClick={onSubmit} disabled={surveyLoading} />
						</p>
						<div className="terms-container">
							<p className="smallgrey">
								*Disclaimer: $500 will be granted once the property signs the Letter of Intent with Flume to install
								service within the community. The $500 giveaway is based on the complete landlord or community
								management information entry. If multiple entries to the same community are submitted, the entry that is
								timestamped earliest will be granted the $500. When the Flume Team contacts the landlord and/or
								community manager, Flume may reference the entrant's name to validate communication. Flume does not
								recommend entering information if the entrant does not want to be sourced. Offer expires on 12/31/2024,
								and entries will not be accepted after 11:59:59pm on 12/31/2024. Offer unavailable for entrants in Simi
								Valley, CA and East Hartford, CT.
							</p>
						</div>
						<div className="NotAvailable_form_submit_divider divider"></div>
					</div>
				)}
				{/*
                <p>Survey loading: {String(surveyLoading)}</p>
                <p>Survey error: {String(surveyError)}</p>
                <p>Survey complete: {String(surveyComplete)}</p>
                <p>First name: <input type="text" value={survey.firstName} onChange={onFirstNameChange} /></p>
                <p><button onClick={onSubmit}>Submit</button></p>
                */}
			</div>
		</div>
	)
}

export default NotAvailablePage
