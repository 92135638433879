import * as moment from "moment"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import InstallPipe from "../../assets/images/install-pipe.svg"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, setStep } from "../../store/store"
import { EbbNav } from "../layout/EbbNav"
import "./Install.scss"

const InstallPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const planPrice = useAppSelector((state) => state.planPrice)
	const useEBB = useAppSelector((state) => state.useEBB)
	const location = useAppSelector((state) => state.location)
	const installStart = useAppSelector((state) => state.subscription.installStart)
	const installEnd = useAppSelector((state) => state.subscription.installEnd)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		if (useEBB) {
			dispatch(setStep(5))
		} else {
			dispatch(setStep(!location?.askForFloor && !location?.askForUnit ? 2 : 3))
		}
	}, [])

	const date = moment(installStart)?.format("ddd, MMM Do")
	const time = `${moment(installStart)?.format("ha")} - ${moment(installEnd)?.format("ha")}`

	return (
		<div className="Install">
			<EbbNav />
			<div className="Install_inner section">
				<div className="Install_container">
					<div className="Install_container_info">
						<h1>{t("install-congrats")}</h1>
						<p>{t("install-info")}</p>
					</div>
					{installStart ? (
						<div className="Install_container_install">
							<div className="Install_container_install_date">
								<div className="pipe">
									<img src={InstallPipe} />
								</div>
								<div className="install-date">
									<p className="smallgrey">{t("install-date")}</p>
									<h1>{date}</h1>
									<p className="smallgrey">{t("install-time-slot")}</p>
									<h1>{time}</h1>
								</div>
							</div>
							<p className="smallgrey">{t("install-confirm")}</p>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default InstallPage
