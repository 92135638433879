import { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Language } from "../../../../lib/ts/lang"
import Checkmark from "../../assets/images/icons/checkmark.svg"
import Info from "../../assets/images/icons/info.svg"
import PlanArrowGreen from "../../assets/images/plan-arrow-green.svg"
import PlanArrowPink from "../../assets/images/plan-arrow-pink.svg"
import { Plan } from "../../store/api"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import {
	navigateToAppHome,
	setIsEventMode,
	setLanguage,
	setPlanID,
	setPlanPrice,
	setPlanType,
	setStep,
} from "../../store/store"
import EditLocation from "../common/EditLocation"
import "./Plan.scss"

const PlanPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const language = useAppSelector((state) => state.language)
	const location = useAppSelector((state) => state.location)
	const selectedPlanID = useAppSelector((state) => state.planID)
	const isEventMode = useAppSelector((state) => state.isEventMode)
	// If this URL is visited directly, there won't be any location loaded.
	// Also, if the user clicks the EditLocation widget, location will be reset.
	// In either case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(0))
		if (sessionStorage.getItem("event-mode") === "on") {
			dispatch(setIsEventMode(true))
		}
	}, [])

	const onSelectPlan = (planID: string, originalPrice: number, planType: number) => {
		dispatch(setPlanID(planID))
		dispatch(setPlanType(planType))
		dispatch(setPlanPrice(originalPrice))
	}
	useEffect(() => {
		if (!selectedPlanID) {
			return
		}
		if (location?.isLowIncome) {
			navigate("/use-ebb")
		} else {
			navigate("/personal")
		}
	}, [selectedPlanID])

	const plans = location?.plans.slice().sort((plan1, plan2) => {
		return plan1.bandwidthMbps - plan2.bandwidthMbps
	})

	useEffect(() => {
		if (!plans) {
			return
		} else if (plans.length === 0) {
			console.error("Zero plans found")
		} else if (plans.length > 2) {
			console.error("More than 2 plans found")
		}
	}, [plans])

	const displayMbps = (mbps: number) => {
		if (mbps % 1000 === 0) {
			return `${mbps / 1000} Gb/s`
		}
		return `${mbps} Mb/s`
	}
	const changeLang = (lang: Language) => {
		dispatch(setLanguage(lang))
	}
	const planDetail = (className: string, plan: Plan, planType: number) => {
		const classNames = [className, "plandetail"].join(" ")
		const price = location?.isLowIncome ? plan.acpPlan.priceCharged / 100 : plan.priceCharged / 100
		const originalPrice = plan.acpPlan.price / 100

		let arrow = PlanArrowPink
		if (className === "plan2") {
			arrow = PlanArrowGreen
		}
		return (
			<div className={classNames} onClick={() => onSelectPlan(plan.id, originalPrice, planType)}>
				<p className="plandetail_title">{plan.displayName}</p>
				<p className="plandetail_speed">{displayMbps(plan.bandwidthMbps)}</p>

				<p className="plandetail_feature">
					<img src={Checkmark} />
					{t("plan-unlimited-data")}
				</p>
				<p className="plandetail_feature">
					<img src={Checkmark} />
					{t("plan-free-wifi-pod")}
				</p>
				<p className="plandetail_feature">
					<img src={Checkmark} />
					{t("plan-free-installation")}
				</p>
				<div className="Plan_container">
					{location?.isLowIncome ? (
						<div>
							<p className="plandetail_price">
								<Trans>
									<span className="plandetail_price_original-price">
										${{ originalPrice } as unknown as string}
										{"   "}
									</span>

									{/* @ts-ignore Fixes type mismatch. */}
									<span>${{ price }}/month</span>
								</Trans>
							</p>
							<p className="plandetail_price_description">{t("for-acp-users")}</p>
							<p className="plandetail_price_description">
								<Trans i18nKey="regular-price-of-package">
									regular <span>${{ originalPrice } as unknown as string}/month</span>
								</Trans>
							</p>
						</div>
					) : (
						<p className="plandetail_price">
							<Trans>
								{/* @ts-ignore Fixes type mismatch. */}
								<span>${{ price }}/month</span>
							</Trans>
						</p>
					)}

					<img src={arrow} />
				</div>
			</div>
		)
	}

	return (
		<div className="Plan">
			<div className="Plan_background">
				<div className="Plan_background_inner">
					<div className="Plan_background_overlay"></div>
					<EditLocation />
				</div>
			</div>
			<div className="Plan_message section">
				{location?.isLowIncome ? (
					<div className="Plan_message_ebb_desktop">
						<div className="Plan_message_ebb_desktop_inner">
							<p>
								<img src={Info} />
							</p>
							<p>
								<strong>{t("plan-free-internet")}</strong>
							</p>
							<p>
								<Trans i18nKey="plan-standard-free">
									You can have our <strong>standard plan for free</strong> with the Affordable Connectivity Program.
									Complete the information in the next steps to see if you are eligible for EBB.
								</Trans>
							</p>
						</div>
					</div>
				) : null}
				{!isEventMode && (
					<div className="Home_language">
						<p className={language === "eng-us" ? "selected" : "not-selected"} onClick={() => changeLang("eng-us")}>
							English
						</p>
						&nbsp;|&nbsp;
						<p className={language === "spa-us" ? "selected" : "not-selected"} onClick={() => changeLang("spa-us")}>
							Español
						</p>
						&nbsp;|&nbsp;
						<p className={language === "zh-us" ? "selected" : "not-selected"} onClick={() => changeLang("zh-us")}>
							中文
						</p>
						&nbsp;|&nbsp;
					</div>
				)}
				<h1>{t("plan-available")}</h1>

				<p>{t("plan-checkout")}</p>
				{location?.isLowIncome ? (
					<div className="Plan_message_ebb_mobile">
						<p>
							<img src={Info} />
						</p>
						<p>
							<strong>{t("plan-free-internet")}</strong>
						</p>
						<p>
							<Trans i18nKey="plan-standard-free">
								You can have our <strong>standard plan for free</strong> with the Affordable Connectivity Program.
								Complete the information in the next steps to see if you are eligible for EBB.
							</Trans>
						</p>
					</div>
				) : null}
			</div>
			<div className="Plan_plans section">
				{plans && plans[0] ? planDetail("plan1", plans[0], 0) : null}
				{plans && plans[1] ? planDetail("plan2", plans[1], 1) : null}
			</div>
		</div>
	)
}

export default PlanPage
