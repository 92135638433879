import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { debounceReact } from "../../../../lib/ts/debounce"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, saveSignup, setApplicationID, setStep } from "../../store/store"
import GoBack from "../common/GoBack"
import LabelInputText from "../common/LabelInputText"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./ApplicationID.scss"

const ApplicationIDPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)
	const applicationID = useAppSelector((state) => state.acpApplicationID)
	const saveSignupLoading = useAppSelector((state) => state.saveSignupLoading)
	const saveSignupError = useAppSelector((state) => state.saveSignupError)
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(applicationID.length < 5) // TODO better validation
	const [applicationIDMissing, setApplicationIDMissing] = useState(false)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(2))
	}, [])

	const debouncedSave = debounceReact(useState<NodeJS.Timeout>(), () => {
		dispatch(saveSignup)
	})

	const onApplicationIDChange = (val: string) => {
		dispatch(setApplicationID(val))
		// TODO need to look at real application ID and do a better validation
		if (val.length > 5) {
			setIsNextButtonDisabled(false)
		} else {
			setIsNextButtonDisabled(true)
		}
		debouncedSave()
	}

	const onSubmit = () => {
		if (!applicationID) {
			setApplicationIDMissing(true)
			return
		}
		setApplicationIDMissing(false)
		dispatch(saveSignup)
		if (!saveSignupError) {
			navigate("/questionnaire")
		}
	}

	const onGoBack = () => {
		navigate("/personal")
	}

	return (
		<div className="ApplicationID">
			<EbbNav />
			<div className="ApplicationID_inner section">
				<GoBack onClick={onGoBack} isGoBackDisabled={saveSignupLoading || isNextButtonDisabled} />

				<div className="ApplicationID_container">
					<div className="ApplicationID_container_info">
						<h1>Application ID</h1>
						<p>Do you already have an Application ID from ACP? If so, enter it here.</p>
						<div className="ApplicationID_container_info_helpimg" />
					</div>
					<div className="ApplicationID_container_form">
						<LabelInputText
							id="applicationid"
							label="Application ID (required)"
							type="text"
							placeholder="Q-555-555"
							value={applicationID}
							onChange={onApplicationIDChange}
							error={saveSignupError}
							errRequired={applicationIDMissing}
						/>
						<div className="ApplicationID_submit">
							<NextButton
								text={t("form-next")}
								onClick={onSubmit}
								disabled={isNextButtonDisabled || saveSignupLoading}
							/>
						</div>
						<p className="error">{typeof saveSignupError === "string" && saveSignupError}</p>
						<h1>What if I don&apos;t have an Application ID?</h1>
						<ol>
							<li>
								Visit&nbsp;
								<a href="https://www.affordableconnectivity.gov/" target="_blank" rel="noreferrer">
									affordableconnectivity.gov
								</a>
							</li>
							<li>Click Apply Now</li>
							<li>Follow the prompts to get qualified for ACP</li>
						</ol>
						<h2>Why do I need an Application ID?</h2>
						<p>
							In order to receive the ACP benefit, you must qualify. The Application ID allows our system to see that
							you are qualified and that ACP will grant you your monthly benefit.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ApplicationIDPage
