import { useTranslation } from "react-i18next"
import CircleBold from "../../assets/images/icons/circle-bold.svg"
import CircleCheck from "../../assets/images/icons/circle-check.svg"
import Circle from "../../assets/images/icons/circle.svg"
import { useAppSelector } from "../../store/hooks"
import "./EbbNav.scss"

export const EbbNav: React.FC = () => {
	const { t } = useTranslation()
	const step = useAppSelector((state) => state.step)
	const useEBB = useAppSelector((state) => state.useEBB)
	const location = useAppSelector((state) => state.location)
	const numSteps = useEBB ? 5 : !location?.askForFloor && !location?.askForUnit ? 2 : 3
	const stepTexts = useEBB
		? [t("step-personal"), t("step-questionnaire"), t("step-verification"), t("step-terms"), t("step-identification")]
		: !location?.askForFloor && !location?.askForUnit
			? [t("step-personal"), t("step-payment")]
			: [t("step-personal"), t("step-apartment"), t("step-payment")]

	const circles = () => {
		const els = []
		for (let i = 1; i < numSteps + 1; i++) {
			if (step > i) {
				els.push(<img src={CircleCheck} key={i} />)
			} else if (step === i) {
				els.push(<img src={CircleBold} key={i} />)
			} else {
				els.push(<img src={Circle} key={i} />)
			}
		}
		return els
	}

	const text = () => {
		const stepNum = `${step}/${numSteps}`
		const stepText = stepTexts[step - 1]
		return `${stepNum} ${stepText}`
	}

	const navs = () => {
		const els = []
		for (let i = 0; i < numSteps; i++) {
			const circle = step > i + 1 ? CircleCheck : Circle
			const classes = ["subnav"]
			if (step >= i + 1) {
				classes.push("subnav-highlight")
			}
			const el = (
				<div className={classes.join(" ")} key={i}>
					<img src={circle} />
					{stepTexts[i]}
				</div>
			)
			els.push(el)
		}
		return els
	}

	return (
		<div className="EbbNav">
			<div className="EbbNav_mobile">
				<div className="EbbNav_mobile_circles">{circles()}</div>
				<div className="EbbNav_mobile_text">{text()}</div>
			</div>
			<div className="EbbNav_desktop section">{navs()}</div>
		</div>
	)
}
