import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../store/hooks"
import "./Error.scss"

const ErrorPage: React.FC = () => {
	const { t } = useTranslation()
	const error = useAppSelector((state) => state.subscription.subscriptionError)

	return (
		<div className="SubmitError section">
			<div className="SubmitError_inner">
				<h1>{t("error")}</h1>
				<p>
					{t("error-processing-subscription")} {error}
				</p>
				<p>
					{t("error-please-call")} <a href="tel:+18559135863">+1 (855) 913-5863</a>
				</p>
			</div>
		</div>
	)
}

export default ErrorPage
