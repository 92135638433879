import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, setStep } from "../../store/store"
import "./NotAvailableResult.scss"

const NotAvailableResultPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)

	// If this URL is visited directly, there won't be any location loaded,
	// and we'll redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(0))
	}, [])

	const locationID = location ? location.locationID : ""
	// const baruchLocationIDs = [
	//     "36c5a2fd-bcfd-48b0-bb23-a501a2220d0d",
	//     "b1414859-9516-4f89-8049-bf5afa6374ce",
	//     "09dcefc5-66a4-4e29-9c45-071041f0e47f",
	//     "6cb98676-0de4-4cc1-a2df-e85828140b43",
	//     "53d54ffc-ee3d-4569-b514-0b251efe30e5",
	//     "31fbb95f-6bb3-41b1-8123-6baec1cf2f2e",
	//     "2fac9146-9189-41e8-8d04-debd4ab5c79f",
	//     "8fd8bb1c-c3d1-4c4d-afe7-f37592d8b5a2",
	//     "fcf48317-3637-43cb-9e90-12b1ac89a38a",
	//     "7d17bc48-1c80-4f1d-9526-a58606129a01",
	//     "c3769c42-82ae-42bf-9780-1cfe354445c3",
	//     "8abe3db3-094c-4810-8bbc-1bfdade7f425",
	//     "aac23989-7b2b-4894-8563-1a4cef9bf6db",
	//     "8354c143-4504-4827-9e17-25defd1720b0",
	//     "0caa982d-f709-418c-9054-0831cebd882a",
	//     "c4158a41-75e2-4f2f-beb7-d96e3bd77747",
	//     "86b910e7-f929-4fa2-88b8-71b34311b0c2",
	//     "8fa681bf-2531-443b-aeea-00c792648811",
	// ]
	// const isBaruch = baruchLocationIDs.indexOf(locationID) >= 0

	const isSifi = location?.market === "simi_valley" || location?.market === "east_hartford"

	let title = t("not-available-result-title")
	let description = t("not-available-result-info")

	// if (isBaruch) {
	//     title = t("not-available-result-baruch-title")
	//     description = t("not-available-result-baruch-info")
	// }
	if (location?.isComingSoon) {
		title = t("not-available-result-coming-soon-title")
		description = t("not-available-result-coming-soon-info")
	}
	if (isSifi) {
		description = t("not-available-result-sifi-info")
	}

	return (
		<div className="NotAvailableResult section">
			<div className="NotAvailableResult_background">
				<div className="NotAvailableResult_background_inner">
					<div className="NotAvailableResult_background_overlay"></div>
				</div>
			</div>
			<div className="NotAvailableResult_text">
				<h1>Thank you!</h1>
				<div className="NotAvailableResult_information">
					<p>We will be in touch when Flume comes to your community.</p>
					<p>If you would like to give your landlord or community board member a heads up, that would be great!</p>
				</div>
			</div>
		</div>
	)
}

export default NotAvailableResultPage
