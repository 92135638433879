import { Dispatch, SetStateAction } from "react"

export function debounce<F extends (...args: Parameters<F>) => void>(
	callback: F,
	delay = 700
): (...args: Parameters<F>) => void {
	let timer: NodeJS.Timeout
	return (...args: Parameters<F>) => {
		clearTimeout(timer)
		timer = setTimeout(() => callback(...args), delay)
	}
}

// To use debounce in a React component:
//     debounceReact(useState<NodeJS.Timeout>(), myFunction)
// This is necessary because debounce() will be called on every render. The state
// needs to be tracked by the React state tracking system.
export function debounceReact<F extends (...args: Parameters<F>) => void>(
	state: [NodeJS.Timeout | undefined, Dispatch<SetStateAction<NodeJS.Timeout | undefined>>],
	callback: F,
	delay = 700
): (...args: Parameters<F>) => void {
	const [timer, setTimer] = state
	return (...args: Parameters<F>) => {
		clearTimeout(timer)
		setTimer(setTimeout(() => callback(...args), delay))
	}
}
