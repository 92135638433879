import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/browser"
import { CaptureConsole } from "@sentry/integrations"
import { Integrations } from "@sentry/tracing"
import config from "config"
import * as i18n from "i18next"
import { Moment } from "moment"
import { initReactI18next } from "react-i18next"
import { NavigateFunction } from "react-router-dom"
import { Language } from "../../../lib/ts/lang"
import fakeMode from "../lib/ts/fake-mode"
import { API, APICard, APISignup, CreateSurveyRequest, FakeAPI, HTTPAPI, LookupAddressData } from "./api"

export type InstallSlot = {
	rangeStart: Moment
	rangeEnd: Moment
}

interface Personal {
	firstName: string
	middleName: string
	lastName: string
	phone: string
	email: string
	allowSMS: boolean
	allowMarketing: boolean
}

interface EBB {
	snap: boolean | undefined
	medicaid: boolean | undefined
	ssi: boolean | undefined
}

interface Subscription {
	subscriptionError: string
	subscriptionComplete: boolean
	installStart: Moment | null
	installEnd: Moment | null
}

interface state {
	// Initial app state.
	initialized: boolean
	initializeError: boolean
	hideNav: boolean
	step: number
	isEventMode: boolean
	signupID: string
	//language
	customerLangauge: string
	language: Language
	signupLoaded: boolean
	// Home (location search)
	locationSearch: string
	placeID: string | null
	pointOfInterestID: string | null
	pointOfInterestSubPremiseID: string | null
	locationLoading: boolean
	locationError: string
	location: LookupAddressData | null
	// NotAvailable (submit survey)
	surveyReq: CreateSurveyRequest
	surveyLoading: boolean
	surveyError: string
	surveyComplete: boolean
	// Plan
	planID: string | null
	planPrice: number
	planType: number
	// UseEBB
	useEBB: boolean | undefined
	// Personal
	personal: Personal
	// Apartment
	floor: number
	unit: string
	// EBB
	ebb: EBB
	acpApplicationID: string
	// DOB
	dobMonth: number
	dobDay: number
	dobYear: number
	// SSN
	ssn: string
	// Referral
	referralCode: string
	howHear: string
	// Payment
	card: APICard
	// End result
	subscription: Subscription
	// SaveSignup
	saveSignupError?: any
	saveSignupLoading: boolean
}

const defaultSurveyReq = (): CreateSurveyRequest => {
	return {
		communityName: "",
		communityPresidentName: "",
		communityPresidentPhone: "",
		communityPresidentEmail: "",
		rentOrOwn: "",
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		address: "",
		floor: 0,
		unit: "N/A",
		// TODO what should this be?
		role: 0,
		allowSMS: true,
		allowMarketing: true,
	}
}

const initialState: state = {
	// Initial app state.
	initialized: false,
	initializeError: false,
	placeID: null,
	pointOfInterestID: null,
	pointOfInterestSubPremiseID: null,
	hideNav: false,
	step: 0,
	isEventMode: false,
	signupID: "",
	customerLangauge: "eng-us",
	language: "eng-us",
	signupLoaded: false,
	saveSignupError: null,
	// Home (location search)
	locationSearch: "",
	locationLoading: true,
	locationError: "",
	location: null,
	// NotAvailable (submit survey)
	surveyReq: defaultSurveyReq(),
	surveyLoading: false,
	surveyError: "",
	surveyComplete: false,
	// Plan
	planID: "",
	planPrice: 0,
	planType: 0,
	// UseEBB
	useEBB: undefined,
	acpApplicationID: "",
	// Personal
	personal: {
		firstName: "",
		middleName: "",
		lastName: "",
		phone: "",
		email: "",
		allowSMS: true,
		allowMarketing: true,
	},
	// Apartment
	floor: 0,
	unit: "",

	// EBB
	ebb: {
		snap: undefined,
		medicaid: undefined,
		ssi: undefined,
	},
	// DOB
	dobMonth: 0,
	dobDay: 0,
	dobYear: 0,
	// SSN
	ssn: "",
	//Referral
	referralCode: "",
	howHear: "",
	// Payment
	card: {
		last4: "",
		expire: "",
		paymentMethodToken: "",
	},
	//saveSignup
	saveSignupLoading: false,
	// End result
	subscription: {
		subscriptionError: "",
		subscriptionComplete: false,
		installStart: null,
		installEnd: null,
	},
}
const slice = createSlice({
	name: "state",
	initialState,
	reducers: {
		dangerousOverrideState: (state, action: PayloadAction<any>) => {
			const keys = Object.keys(action.payload)
			for (const key of keys) {
				// @ts-ignore TODO we can do this in a type-safe way...
				state[key] = action.payload[key]
			}
		},
		init: (
			state,
			action: PayloadAction<{
				placeID: string
			}>
		) => {
			state.placeID = action.payload.placeID
			state.locationLoading = !!action.payload.placeID
			state.initialized = true
		},
		setLanguage: (state, action: PayloadAction<Language>) => {
			state.language = action.payload
			if (!state.isEventMode) {
				i18n.use(initReactI18next).changeLanguage(action.payload)
			}
		},
		setLocationSearch: (state, action: PayloadAction<string>) => {
			state.locationSearch = action.payload
		},
		setLocationPlaceID: (state, action: PayloadAction<string>) => {
			state.placeID = action.payload
		},
		setLocation: (
			state,
			action: PayloadAction<{
				loading: boolean
				error: string
				location: LookupAddressData | null
			}>
		) => {
			state.locationLoading = action.payload.loading
			state.locationError = action.payload.error
			state.location = action.payload.location
		},
		setSurveyReq: (state, action: PayloadAction<CreateSurveyRequest>) => {
			state.surveyReq = action.payload
		},
		setSurvey: (
			state,
			action: PayloadAction<{
				loading: boolean
				error: string
				complete: boolean
			}>
		) => {
			state.surveyLoading = action.payload.loading
			state.surveyError = action.payload.error
			state.surveyComplete = action.payload.complete
		},
		setPlanID: (state, action: PayloadAction<string | null>) => {
			state.planID = action.payload
			state.hideNav = true
		},
		setPlanPrice: (state, action: PayloadAction<number>) => {
			state.planPrice = action.payload
		},
		setUseEBB: (state, action: PayloadAction<boolean | undefined>) => {
			state.useEBB = action.payload
		},
		setCustomerLanguage: (state, action: PayloadAction<string>) => {
			state.customerLangauge = action.payload
		},
		reset: (state) => {
			state.placeID = ""
			state.locationSearch = ""
			state.location = null
			state.surveyReq = defaultSurveyReq()
		},
		setPersonal: (state, action: PayloadAction<Personal>) => {
			state.personal = action.payload
		},
		setApplicationID: (state, action: PayloadAction<string>) => {
			state.acpApplicationID = action.payload
		},
		setStep: (state, action: PayloadAction<number>) => {
			state.step = action.payload
			state.hideNav = action.payload > 0
		},
		setFloor: (state, action: PayloadAction<number>) => {
			state.floor = action.payload
		},
		setUnit: (state, action: PayloadAction<string>) => {
			state.unit = action.payload
		},
		setReferralCode: (state, action: PayloadAction<string>) => {
			state.referralCode = action.payload
		},
		setPlatform: (state, action: PayloadAction<string>) => {
			state.howHear = action.payload
		},
		setEBB: (state, action: PayloadAction<EBB>) => {
			state.ebb = action.payload
		},
		setSaveSignupError: (state, action: PayloadAction<any>) => {
			state.saveSignupError = action.payload
		},
		setDOBMonth: (state, action: PayloadAction<number>) => {
			state.dobMonth = action.payload
		},
		setDOBDay: (state, action: PayloadAction<number>) => {
			state.dobDay = action.payload
		},
		setDOBYear: (state, action: PayloadAction<number>) => {
			state.dobYear = action.payload
		},
		setSSN: (state, action: PayloadAction<string>) => {
			state.ssn = action.payload
		},
		setSignupID: (state, action: PayloadAction<string>) => {
			state.signupID = action.payload
		},
		setCard: (state, action: PayloadAction<APICard>) => {
			state.card = action.payload
		},
		setIsEventMode: (state, action: PayloadAction<boolean>) => {
			state.isEventMode = action.payload
		},
		setPlanType: (state, action: PayloadAction<number>) => {
			state.planType = action.payload
		},
		setSubscription: (state, action: PayloadAction<Subscription>) => {
			state.subscription = action.payload
		},
		setSaveSignupLoading: (state, action: PayloadAction<boolean>) => {
			state.saveSignupLoading = action.payload
		},
		setSignupLoaded: (state, action: PayloadAction<boolean>) => {
			state.signupLoaded = action.payload
		},
	},
})

const store = configureStore({ reducer: slice.reducer })
export default store

export type GetState = typeof store.getState
export type State = ReturnType<GetState>
export type AppDispatch = typeof store.dispatch

export const {
	init,
	setLanguage,
	setCustomerLanguage,
	setLocationSearch,
	setLocationPlaceID,
	setLocation,
	setSurveyReq,
	setSurvey,
	setPlanID,
	setPlanPrice,
	setPlanType,
	setUseEBB,
	setIsEventMode,
	reset,
	setPersonal,
	setApplicationID,
	setStep,
	setFloor,
	setUnit,
	setReferralCode,
	setPlatform,
	setSaveSignupError,
	setEBB,
	setDOBMonth,
	setDOBDay,
	setDOBYear,
	setSSN,
	setSignupID,
	setCard,
	setSubscription,
	setSaveSignupLoading,
	setSignupLoaded,
} = slice.actions

// Load fake mode using a URL like http://localhost:8080/?fake
// This is useful for local UI development without needing to run
// an API locally (or before the API is built).
let api: API
if (fakeMode()) {
	api = new FakeAPI()
} else {
	api = new HTTPAPI()
}

// Called on app load.
export const initialize = (dispatch: AppDispatch, getState: GetState) => {
	console.log("initialize()")

	if (config.useSentry) {
		Sentry.init({
			dsn: "https://934e73581c254724ac0ea05b8714fa37@o609621.ingest.sentry.io/5747621",
			environment: config.sentryEnvironment,
			integrations: [new Integrations.BrowserTracing(), new CaptureConsole({ levels: ["warn", "error"] })],
			tracesSampleRate: config.sentrySampleRate === undefined ? 1.0 : config.sentrySampleRate,
		})
	}
	const queryParams = new URLSearchParams(window.location.search)
	const placeID = queryParams.get("placeID")

	dispatch(
		init({
			placeID: placeID || "",
		})
	)

	if (placeID) {
		dispatch(lookupLocation)
	}
}

// Called when searching an address.
export const lookupLocation = (dispatch: AppDispatch, getState: GetState) => {
	console.log("lookupLocation()")
	dispatch(setLocation({ loading: true, error: "", location: null }))
	const state = getState()

	api
		.lookupAddress({
			placeID: state.placeID,
			formattedAddress: state.locationSearch,
			pointOfInterestID: state.pointOfInterestID,
			pointOfInterestSubPremiseID: state.pointOfInterestSubPremiseID,
		})
		.then((res) => {
			if (!res.ok) {
				if (res.validationErrors === null) {
					return dispatch(
						setLocation({
							loading: false,
							error: res.error,
							location: null,
						})
					)
				}

				return dispatch(
					setLocation({
						loading: false,
						error: res.validationErrors.address,
						location: null,
					})
				)
			}

			return dispatch(
				setLocation({
					loading: false,
					error: "",
					location: res.data,
				})
			)
		})
		.catch((err) => {
			console.error(err)
			dispatch(setLocation({ loading: false, error: err, location: null }))
		})
}

// Called when submitting a survey.

export const handleSubmitSurvey = (navigate: NavigateFunction) => {
	return async function submitSurvey(dispatch: AppDispatch, getState: GetState) {
		console.log("submitSurvey()")
		dispatch(setSurvey({ loading: true, error: "", complete: false }))

		const state = getState()
		if (!state.location) {
			console.error("submitSurvey() called with no location")
			dispatch(
				setSurvey({
					loading: false,
					error: "No location provided",
					complete: false,
				})
			)
			return
		}
		const survey = { ...state.surveyReq }
		survey.address = state.location.address
		survey.floor = state.floor
		survey.unit = state.unit
		api
			.createSurvey(survey)
			.then((res) => {
				if (!res.ok) {
					dispatch(
						setSurvey({
							loading: false,
							error: res.error,
							complete: false,
						})
					)

					return
				}

				dispatch(setSurvey({ loading: false, error: "", complete: true }))
				navigate("/not-available")
			})
			.catch((err) => {
				console.error(err)
				dispatch(setSurvey({ loading: false, error: err, complete: false }))
			})
	}
}
const stateToSignup = (state: State): APISignup => {
	if (!state.location) {
		throw new Error("stateToSignup: no location")
	}
	const apiSignup: APISignup = {
		language: state.language,
		location: {
			id: state.location.locationID,
			address: state.location.address,
			floor: state.floor,
			apt: state.unit,
		},
		planID: state.planID,
		useEBB: state.useEBB || false,
		referralCode: state.referralCode,
		howHear: state.howHear,
		customer: {
			firstName: state.personal.firstName,
			middleName: state.personal.middleName,
			lastName: state.personal.lastName,
			phone: state.personal.phone,
			email: state.personal.email,
			skipEmail: Boolean(state.personal.email),
			dobMonth: state.dobMonth,
			dobDay: state.dobDay,
			dobYear: state.dobYear,
			last4SSN: state.ssn,
			skipSSN: false,
		},
		ebb: {
			hasSnap: state.ebb.snap,
			hasMedicaid: state.ebb.medicaid,
			hasSSI: state.ebb.ssi,
			hasSchoolLunch: undefined,
			hasPellGrant: undefined,
			hasVeterans: undefined,
			hasSubstantialLoss: undefined,
			hasLowIncome: undefined,
			applicationID: state.acpApplicationID,
			bqp: {
				firstName: "",
				lastName: "",
				dobMonth: 0,
				dobDay: 0,
				dobYear: 0,
				last4SSN: "",
			},
			meta: {
				nvNotImplemented: false,
				eligabilityCheckID: "",
				status: "",
				certificationLink: "",
			},
		},
		skipReferral: false,
		skipCard: false,
		card: state.card,
		allowSMS: state.personal.allowSMS,
		allowMarketing: state.personal.allowMarketing,
	}
	return apiSignup
}

export const saveSignup = (dispatch: AppDispatch, getState: GetState) => {
	console.log("saveSignup()")
	const state = getState()
	if (!state.personal.phone) {
		return
	}

	setTimeout(() => {
		api
			.saveSignup({
				id: state.signupID,
				state: stateToSignup(state),
			})
			.then((res) => {
				dispatch(setSaveSignupLoading(false))
				if (!res.ok) {
					if (res.validationErrors === null) {
						dispatch(setSaveSignupError(res.error))
					} else {
						dispatch(setSaveSignupError(res.validationErrors))
					}
					return
				} else {
					dispatch(setSaveSignupError(null))
					dispatch(setSignupID(res.data.id))
					Sentry.setUser({ id: res.data.id })
				}
			})
			.catch((err) => {
				const wrongCopy = i18n.use(initReactI18next).t("something-went-wrong")
				dispatch(setSaveSignupError(wrongCopy))
				console.error("saveSignup", err)
				dispatch(setSaveSignupLoading(false))
			})
	}, 0)
}

export const finishSignup = (dispatch: AppDispatch, getState: GetState) => {
	console.log("finishSignup()")
	const state = getState()

	const data = {
		signupID: state.signupID,
		stripePaymentMethodToken: state.card.paymentMethodToken,
		cardLast4: state.card.last4,
		cardExpire: state.card.expire,
	}
	setTimeout(() => {
		api
			.finishSignup(data)
			.then((res) => {
				if (!res.ok) {
					dispatch(
						setSubscription({
							subscriptionError: res.error,
							subscriptionComplete: false,
							installStart: null,
							installEnd: null,
						})
					)
					return
				}
				dispatch(
					setSubscription({
						subscriptionError: "",
						subscriptionComplete: true,
						installStart: res.data.installDate.rangeStart,
						installEnd: res.data.installDate.rangeEnd,
					})
				)
			})
			.catch((err) => {
				console.error(err)
				dispatch(
					setSubscription({
						subscriptionError: err,
						subscriptionComplete: false,
						installStart: null,
						installEnd: null,
					})
				)
			})
	}, 0)
}

export function navigateToAppHome() {
	console.debug("navigateToAppHome()")
	window.location.assign("https://app.flumeinternet.com/service/search")
}
