import { useState } from "react"
import { useTranslation } from "react-i18next"
import Hamburger from "../../../../lib/assets/images/hamburger.svg"
import Facebook from "../../../../lib/assets/images/icons/facebook.svg"
import FCC from "../../../../lib/assets/images/icons/fcc.svg"
import LinkedIn from "../../../../lib/assets/images/icons/linkedin.svg"
import Twitter from "../../../../lib/assets/images/icons/twitter.svg"
import Logo from "../../../../lib/assets/images/logo.svg"
import config, { COPYRIGHT_LINE } from "../../config"
import { useAppSelector } from "../../store/hooks"
import "./Layout.scss"

interface LayoutProps {
	children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const { t } = useTranslation()
	const hideNav = useAppSelector((state) => state.hideNav)
	const [hamburger, setHamburger] = useState<boolean>(false)

	const link = (path: string) => {
		return `${config.marketingSiteURL}${path}`
	}

	return (
		<div className="Layout">
			<div className="Layout_inner">
				<div className="Layout_top">
					<div className="Layout_top_logo">
						<a href={link("/")}>
							<img src={Logo} />
						</a>
					</div>
					{!hideNav && (
						<div className="Layout_top_nav">
							<div className="Layout_top_nav_mobile">
								<a href={new URL("/login", config.customerPortalURL).toString()} className="Button">
									{t("nav-login")}
								</a>
								<img src={Hamburger} style={{ fill: "black" }} onClick={() => setHamburger(!hamburger)} />
							</div>
							<div className="Layout_top_nav_desktop">
								<a href={link("/tenants")} className="Navlink">
									Tenants
								</a>
								<a href={link("/properties")} className="Navlink">
									{t("nav-property-owners")}
								</a>
								<a href={link("/blog")} className="Navlink">
									Blog
								</a>
								<a href={link("/about")} className="Navlink">
									{t("nav-about")}
								</a>
								<a href={new URL("/login", config.customerPortalURL).toString()} className="Button">
									{t("nav-login")}
								</a>
							</div>
						</div>
					)}
				</div>
				<div className="Layout_top_hamburger">
					{hamburger ? (
						<div className="Layout_top_hamburger_inner">
							<div>
								<a href={link("/internet")} className="Navlink">
									{t("nav-internet")}
								</a>
							</div>
							<div>
								<a href={link("/properties")} className="Navlink">
									{t("nav-property-owners")}
								</a>
							</div>
							<div>
								<a href={link("/acp")} className="Navlink">
									{t("nav-acp")}
								</a>
							</div>
							<div>
								<a href={link("/about")} className="Navlink">
									{t("nav-about")}
								</a>
							</div>
						</div>
					) : null}
				</div>
				{children}
				{!hideNav && (
					<div className="Layout_bottom_mobile section">
						<div className="Layout_bottom_mobile_contact">
							<a href={link("/")}>
								<img src={Logo} />
							</a>
							<p>
								<a href="tel:+18559135863" className="Navlink">
									+1 (855) 913-5863
								</a>
							</p>
							<p>
								<a href="mailto:hello@flumeinternet.com" className="Navlink">
									hello@flumeinternet.com
								</a>
							</p>
							<p>
								<a href={config.customerPortalURL} className="Navlink">
									Subscriber Portal
								</a>
							</p>
						</div>
						<div className="Layout_bottom_mobile_links">
							<div className="Layout_bottom_mobile_links_left">
								<p>
									<a href={link("/internet")} className="Navlink">
										{t("nav-internet")}
									</a>
								</p>
								<p>
									<a href={link("/properties")} className="Navlink">
										{t("nav-property-owners")}
									</a>
								</p>
								<p>
									<a href={link("/new-cities")} className="Navlink">
										{t("nav-new-cities")}
									</a>
								</p>
							</div>
							<div className="Layout_bottom_mobile_links_right">
								<p>
									<a href={link("/acp")} className="Navlink">
										{t("nav-acp-program")}
									</a>
								</p>
								<p>
									<a href={link("/about")} className="Navlink">
										{t("nav-about")}
									</a>
								</p>
								<p>
									<a href={link("/careers")} className="Navlink">
										{t("nav-careers")}
									</a>
								</p>
								<p>
									<a href={link("/construction")} className="Navlink">
										{t("nav-construction")}
									</a>
								</p>
							</div>
						</div>
						<div className="Layout_bottom_mobile_divider divider"></div>
						<div className="Layout_bottom_mobile_links">
							<div className="Layout_bottom_mobile_links_left Layout_bottom_mobile_copyright">
								<p>{COPYRIGHT_LINE}</p>
							</div>
							<div className="Layout_bottom_mobile_links_right">
								<p>
									<a href={link("/privacy-policy")} className="Navlink">
										{t("nav-privacy-policy")}
									</a>
								</p>
								<p>
									<a href={link("/terms-of-service")} className="Navlink">
										{t("nav-terms-of-service")}
									</a>
								</p>
							</div>
						</div>
					</div>
				)}
				{!hideNav && (
					<div className="Layout_bottom_desktop section">
						<div className="Layout_bottom_desktop_container">
							<div className="Layout_bottom_desktop_contact">
								<a href={link("/")}>
									<img src={Logo} />
								</a>
								<p>
									<a href="tel:+18559135863" className="Navlink">
										+1 (855) 913-5863
									</a>
								</p>
								<p>
									<a href="mailto:hello@flumeinternet.com" className="Navlink">
										hello@flumeinternet.com
									</a>
								</p>
								<p>
									<a href={config.customerPortalURL} className="Navlink">
										Subscriber Portal
									</a>
								</p>
							</div>
							<div className="Layout_bottom_desktop_links">
								<p>
									<a href={link("/tenants")} className="Navlink">
										Tenants
									</a>
								</p>
								<p>
									<a href={link("/properties")} className="Navlink">
										Properties
									</a>
								</p>
								<p>
									<a href={link("/blog")} className="Navlink">
										Blog
									</a>
								</p>
								<p>
									<a href={link("/about")} className="Navlink">
										About
									</a>
								</p>
							</div>
						</div>
						<div className="Layout_bottom_desktop_divider divider"></div>
						<div className="Layout_bottom_desktop_container">
							<div className="Layout_bottom_desktop_copyright">
								<p>
									<a href="https://www.facebook.com/FlumeInternet/">
										<img src={Facebook} />
									</a>
								</p>
								<p>
									<a href="https://twitter.com/flumeinternet">
										<img src={Twitter} />
									</a>
								</p>
								<p>
									<a href="https://www.linkedin.com/company/flume-internet/">
										<img src={LinkedIn} />
									</a>
								</p>
								<p>{COPYRIGHT_LINE}</p>
							</div>
							<div className="Layout_bottom_desktop_links">
								<p>
									<a href={link("/privacy-policy")} className="Navlink">
										{t("nav-privacy-policy")}
									</a>
								</p>
							</div>
							<div className="Layout_bottom_desktop_links">
								<p>
									<a href={link("/terms-of-service")} className="Navlink">
										{t("nav-terms-of-service")}
									</a>
								</p>
							</div>
						</div>
					</div>
				)}
				{hideNav ? (
					<div className="Layout_navhidden_bottom section">
						<div className="Layout_navhidden_bottom_divider divider"></div>
						<div className="Layout_navhidden_bottom_container">
							<div className="Layout_navhidden_bottom_copyright">{COPYRIGHT_LINE}</div>
							<div className="Layout_navhidden_bottom_fcc">
								<img src={FCC} />
								{/*
                                <p className={"fcc"}>Federal<br />Communications<br />Commission</p>
                                */}
								<p>{t("nav-acp-approved")}</p>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default Layout
