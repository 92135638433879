import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { debounceReact } from "../../../../lib/ts/debounce"
import { Language } from "../../../../lib/ts/lang"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import {
	navigateToAppHome,
	saveSignup,
	setCustomerLanguage,
	setIsEventMode,
	setLanguage,
	setPlatform,
	setReferralCode,
	setSaveSignupError,
	setSaveSignupLoading,
} from "../../store/store"
import GoBack from "../common/GoBack"
import LabelInputText from "../common/LabelInputText"
import LabelSelect from "../common/LabelSelect"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./Apartment.scss"

const ReferFriendPage: React.FC = () => {
	type languages = {
		language: string
		tag: Language
	}
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const saveSignupError = useAppSelector((state) => state.saveSignupError)
	const location = useAppSelector((state) => state.location)
	const platform = useAppSelector((state) => state.howHear)
	const saveSignupLoading = useAppSelector((state) => state.saveSignupLoading)
	const referralCode = useAppSelector((state) => state.referralCode)
	const customerLangauge = useAppSelector((state) => state.customerLangauge)
	const useEBB = useAppSelector((state) => state.useEBB)
	const [missing, setMissing] = useState<any>({})
	const isNextButtonDisabled = Boolean(saveSignupError)
	const isEventMode = useAppSelector((state) => state.isEventMode)
	const eventName = sessionStorage.getItem("event-name")
	const platformOptions = [
		"Recommended by a friend/neighbor",
		"Henry Street/Jobs Plus",
		"Local Event",
		"Search Engine (Google, Yahoo, Bing, etc.)",
		"Social Media",
		"Flyer, Door Hanger, etc.",
		"Tech - Raven",
		"Other",
	]
	const languages: Array<languages> = [
		{ language: "English", tag: "eng-us" },
		{ language: "Spanish", tag: "spa-us" },
		{ language: "Chinese", tag: "zh-us" },
	]

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (sessionStorage.getItem("event-mode") === "on") {
			dispatch(setIsEventMode(true))
		}
		if (!location) return navigateToAppHome()
	}, [location])

	const debouncedSave = debounceReact(useState<NodeJS.Timeout>(), () => {
		dispatch(saveSignup)
	})

	const onPlatformChange = (val: string) => {
		dispatch(setPlatform(val))
		debouncedSave()
	}
	const onLanguageChange = (val: string) => {
		const language = languages.find((lang) => lang.language === val)
		if (language && eventName) {
			dispatch(setCustomerLanguage(language?.language))
			dispatch(setPlatform(eventName))
			dispatch(setLanguage(language?.tag))
			debouncedSave()
		}
	}

	const onCodeChange = (val: string) => {
		dispatch(setSaveSignupLoading(true))
		dispatch(setReferralCode(val))
		debouncedSave()
	}

	const onSubmit = () => {
		const missing: any = {}
		if (!customerLangauge) {
			missing.customerLangauge = true
		}
		if (Object.keys(missing).length > 0) {
			setMissing(missing)
			return
		}
		if (!saveSignupError) {
			navigate("/payment")
		}
	}

	const onGoBack = () => {
		dispatch(setSaveSignupError(null))
		if (useEBB) {
			navigate("/terms")
		} else {
			navigate("/apartment")
		}
	}

	return (
		<div className="Apartment">
			<EbbNav />
			<div className="Apartment_inner section">
				<GoBack onClick={onGoBack} isGoBackDisabled={saveSignupLoading || isNextButtonDisabled} />
				<div className="Apartment_container">
					<div className="Apartment_container_info">
						<h1>{isEventMode ? "Primary language of user" : t("referral-scheme")}</h1>
						{isEventMode ? (
							<p>Select language of the user</p>
						) : (
							<p>
								{t("referral-scheme-info")}
								{location?.allowReferrals ? t("referral-scheme-info-referral-allowed") : ""}
							</p>
						)}
					</div>
					<div className="Apartment_container_form">
						{isEventMode ? (
							<LabelSelect
								id="user-language"
								label={"User's Language"}
								options={languages.map((language) => language.language)}
								onChange={onLanguageChange}
								value={customerLangauge}
								errRequired={missing.customerLangauge}
								error={saveSignupError?.language}
							/>
						) : (
							<>
								<LabelSelect
									id="referal-platform"
									label={t("form-referal-platform")}
									options={platformOptions}
									onChange={onPlatformChange}
									value={platform}
									error={saveSignupError?.howHear}
								/>
								{location?.allowReferrals ? (
									<LabelInputText
										id="referal-code"
										type="text"
										label={t("form-referal-code")}
										placeholder={t("form-referal-code")}
										value={referralCode}
										error={saveSignupError?.referralCode}
										onChange={onCodeChange}
									/>
								) : null}
							</>
						)}
						<div className="Apartment_submit">
							<NextButton
								text={t("form-next")}
								onClick={onSubmit}
								disabled={isNextButtonDisabled || saveSignupLoading}
							/>
						</div>
						<p className="error">{typeof saveSignupError === "string" && saveSignupError}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReferFriendPage
