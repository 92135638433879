import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { debounceReact } from "../../../../lib/ts/debounce"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, saveSignup, setSSN, setSaveSignupError, setStep } from "../../store/store"
import GoBack from "../common/GoBack"
import LabelInputText from "../common/LabelInputText"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./SSN.scss"

const SSNPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const saveSignupError = useAppSelector((state) => state.saveSignupError)
	const location = useAppSelector((state) => state.location)
	const ssn = useAppSelector((state) => state.ssn)
	const [missing, setMissing] = useState<any>({})
	const isNextButtonDisabled = Boolean(saveSignupError)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		dispatch(setStep(3))
	}, [])

	const debouncedSave = debounceReact(useState<NodeJS.Timeout>(), () => {
		dispatch(saveSignup)
	})

	const onChange = (val: string) => {
		dispatch(setSSN(val))
		debouncedSave()
	}

	const onSubmit = () => {
		const missing: any = {}
		if (!ssn) {
			missing.ssn = true
		}
		if (Object.keys(missing).length > 0) {
			setMissing(missing)
			return
		}
		if (!saveSignupError) {
			navigate("/terms")
		}
	}

	const onGoBack = () => {
		dispatch(setSaveSignupError(null))
		navigate("/birth-date")
	}

	return (
		<div className="SSN">
			<EbbNav />
			<div className="SSN_inner section">
				<GoBack onClick={onGoBack} />
				<div className="SSN_container">
					<div className="SSN_container_info">
						<h1>{t("ssn-details")}</h1>
						<p>{t("ssn-details-info")}</p>
					</div>
					<div className="SSN_container_form">
						<div className="SSN_container_form_fields">
							<LabelInputText
								id="ssn-ssn"
								label={t("form-ssn")}
								placeholder="1234"
								type="text"
								pattern="\d*"
								maxlength={4}
								value={ssn}
								error={saveSignupError?.ssn}
								onChange={onChange}
								errRequired={missing.ssn}
							/>
						</div>
						<div className="SSN_submit">
							<NextButton text={t("form-next")} onClick={onSubmit} disabled={isNextButtonDisabled} />
						</div>
						<p className="error">{typeof saveSignupError === "string" && saveSignupError}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SSNPage
