import { Env, selectedEnv } from "./lib/ts/env"

type config = {
	apiHost: string
	apiScheme: string
	stripeKey: string
	customerPortalURL: string
	useSentry: boolean
	useFacebookPixel: boolean
	sentryEnvironment: Env
	sentrySampleRate?: number // From 0 to 1
	adminPass: string
	marketingSiteURL: string
}

const dev: config = {
	apiHost: "localhost:8090",
	apiScheme: "http",
	stripeKey: "pk_test_ghaTp4p89HSlI2TjsLLRxP6M00f5RKYinp",
	customerPortalURL: "http://localhost:4000",
	useSentry: false,
	useFacebookPixel: false,
	sentryEnvironment: "dev",
	adminPass: "flumeTakeover2021!",
	marketingSiteURL: "https://www.flumeinternet.com",
}

const tst: config = dev

const stg: config = {
	apiHost: "api.flumeinternet.cool",
	apiScheme: "https",
	stripeKey: "pk_test_ghaTp4p89HSlI2TjsLLRxP6M00f5RKYinp",
	customerPortalURL: "https://app.flumeinternet.cool",
	useSentry: true,
	useFacebookPixel: false,
	sentryEnvironment: "stg",
	adminPass: "flumeTakeover2021!",
	marketingSiteURL: "https://www.flumeinternet.cool",
}

const prd: config = {
	apiHost: "api.flumeinternet.com",
	apiScheme: "https",
	stripeKey: "pk_live_pXzKKCuBMM4XfVfxmoZwRhbO007vbwrKXQ",
	customerPortalURL: "https://app.flumeinternet.com",
	useSentry: true,
	useFacebookPixel: true,
	sentryEnvironment: "prd",
	sentrySampleRate: 1.0,
	adminPass: "flumeTakeover2021!",
	marketingSiteURL: "https://www.flumeinternet.com",
}

let cfg: config = dev
if (selectedEnv === "tst") {
	cfg = tst
}
if (selectedEnv === "stg") {
	cfg = stg
}
if (selectedEnv === "prd") {
	cfg = prd
}

export default { ...cfg }

export const COPYRIGHT_LINE = `© ${new Date().getFullYear()} Flume Internet, Inc.`
