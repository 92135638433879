import ApartmentComponent from "components/common/ApartmentComponent"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { debounceReact } from "../../../../lib/ts/debounce"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { navigateToAppHome, saveSignup, setFloor, setSaveSignupError, setStep, setUnit } from "../../store/store"
import GoBack from "../common/GoBack"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./Apartment.scss"

const ApartmentPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const saveSignupError = useAppSelector((state) => state.saveSignupError)
	const location = useAppSelector((state) => state.location)
	const floor = useAppSelector((state) => state.floor)
	const unit = useAppSelector((state) => state.unit)
	const useEBB = useAppSelector((state) => state.useEBB)
	const applicationID = useAppSelector((state) => state.acpApplicationID)
	const isNextButtonDisabled = Boolean(saveSignupError)
	const [missing, setMissing] = useState<any>({})
	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()

		if (!location.askForFloor && !location.askForUnit) {
			if (useEBB) {
				navigate("/birth-date")
			} else {
				navigate("/refer")
			}
		}
	}, [])

	useEffect(() => {
		if (useEBB) {
			dispatch(setStep(3))
		} else {
			dispatch(setStep(2))
		}
	}, [useEBB])

	const debouncedSave = debounceReact(useState<NodeJS.Timeout>(), () => {
		dispatch(saveSignup)
	})

	const onFloorChange = (val: string) => {
		dispatch(setFloor(Number(val)))
		debouncedSave()
	}

	const onUnitChange = (val: string) => {
		dispatch(setUnit(val))
		debouncedSave()
	}

	const onSubmit = () => {
		const missing: any = {}
		if (!floor) {
			missing.floor = true
		}
		if (!unit) {
			missing.unit = true
		}
		if (Object.keys(missing).length > 0) {
			setMissing(missing)
			return
		}
		if (!saveSignupError) {
			if (useEBB) {
				navigate("/birth-date")
			} else {
				navigate("/refer")
			}
		}
	}

	const onGoBack = () => {
		dispatch(setSaveSignupError(null))
		if (useEBB) {
			navigate("/questionnaire")
		} else {
			navigate("/personal")
		}
	}

	return (
		<div className="Apartment">
			<EbbNav />
			<div className="Apartment_inner section">
				<GoBack onClick={onGoBack} />
				<div className="Apartment_container">
					<div className="Apartment_container_info">
						<h1>{t("apartment-details")}</h1>
						<p>{t("apartment-details-info")}</p>
					</div>
					<div className="Apartment_container_form">
						<ApartmentComponent
							floor={floor}
							unit={unit}
							onUnitChange={onUnitChange}
							onFloorChange={onFloorChange}
							missing={missing}
							saveSignupError={saveSignupError}
							fieldsRequired
						/>
						<div className="Apartment_submit">
							<NextButton text={t("form-next")} onClick={onSubmit} disabled={isNextButtonDisabled} />
						</div>
						<p className="error">{typeof saveSignupError === "string" && saveSignupError}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ApartmentPage
