import * as i18n from "i18next"
import { initReactI18next } from "react-i18next"

// See: https://react.i18next.com/getting-started
i18n.use(initReactI18next).init({
	// Without this option, your language locales need to be uppercased.
	// For example "eng-US" instead of "eng-us".
	// This is dumb, of course, but at least they have this handy configuration.
	lowerCaseLng: true,
	// Without this option, some browsers throw an error about plurals.
	// We don't use the i18next plurals feature.
	// See: https://stackoverflow.com/questions/70493788/i18nextpluralresolver-your-environment-seems-not-to-be-intl-api-compatible-u
	// See: https://www.i18next.com/misc/migration-guide#v20.x.x-to-v21.0.0
	compatibilityJSON: "v3",
	resources: {
		"eng-us": {
			translation: require("../../assets/translations/eng-us.json"),
		},
		"spa-us": {
			translation: require("../../assets/translations/spa-us.json"),
		},
		"zh-us": {
			translation: require("../../assets/translations/zh-us.json"),
		},
	},
	lng: "eng-us",
	fallbackLng: "eng-us",
})
