import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Plan } from "store/api"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { finishSignup, navigateToAppHome, setCard, setStep } from "../../store/store"
import GoBack from "../common/GoBack"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./Payment.scss"

const PaymentPage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)
	const useEBB = useAppSelector((state) => state.useEBB)
	const card = useAppSelector((state) => state.card)
	const stripe = useStripe()
	const stripeElements = useElements()
	const [stripeError, setStripeError] = useState<string>("")
	const selectedPlanID = useAppSelector((state) => state.planID)
	const findSelectedPlanID = (plan: Plan) => {
		if (useEBB) {
			return plan.acpPlan.id === selectedPlanID
		} else {
			return plan.id === selectedPlanID
		}
	}
	const selectedPlan = location?.plans.find(findSelectedPlanID)
	const price = useEBB ? selectedPlan?.acpPlan.priceCharged : selectedPlan?.priceCharged
	const cardOptions = {
		hideIcon: true,
		style: {
			base: {
				color: "#31344A",
				backgroundColor: "#ffffff",
				fontFamily: `"Untitled Sans", sans-serif`,
				fontSize: "16px",
			},
			invalid: {
				color: "#c15353",
			},
		},
	}
	const cardEl = <CardElement options={cardOptions} />

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [location])

	useEffect(() => {
		if (useEBB) {
			dispatch(setStep(5))
		} else {
			dispatch(setStep(!location?.askForFloor && !location?.askForUnit ? 2 : 3))
		}
	}, [useEBB])
	useEffect(() => {
		if (!card.paymentMethodToken) {
			return
		}
		dispatch(finishSignup)
		navigate("/install")
	}, [card])

	/*
    useEffect(() => {
        if (price === undefined) {
            return
        }
        if (price !== 0) {
            return
        }
        dispatch(finishSignup)
        navigate("/install")
    }, [price])
     */

	const onSubmit = () => {
		// TODO handle stripe not existing...
		stripe!
			.createPaymentMethod({
				type: "card",
				card: stripeElements!.getElement(CardElement)!,
			})
			.then((res) => {
				if (!res || !res.paymentMethod || !res.paymentMethod.card) {
					setStripeError(res?.error?.message || "There was an issue with your card: ERR_UNKNOWN")
					return
				}
				dispatch(
					setCard({
						last4: res.paymentMethod.card.last4,
						expire: `${res.paymentMethod.card.exp_month}/${res.paymentMethod.card.exp_year}`,
						paymentMethodToken: res.paymentMethod.id,
					})
				)
			})
			.catch((err) => {
				setStripeError("There was an issue with your card: ERR_NO_RESPONSE")
				console.error(err)
			})
	}

	const onGoBack = () => {
		if (useEBB) {
			navigate("/refer")
		} else {
			navigate("/refer")
		}
	}

	return (
		<div className="Payment">
			<EbbNav />
			<div className="Payment_inner section">
				<GoBack onClick={onGoBack} />
				<div className="Payment_container">
					<div className="Payment_container_info">
						{!useEBB && <h1>{t("payment-info")}</h1>}
						{!useEBB && <p>{t("payment-info-info")}</p>}
						{useEBB ? <h1>{t("identification")}</h1> : null}
						{useEBB ? (
							<p>
								<Trans i18nKey="identification-info">
									Please enter your credit or debit card number. The data will be used to verify your identity, but your
									account will <strong>not</strong> be charged and&nbsp;
									<strong>thanks to ACP the plan will be available for free.</strong>
								</Trans>
							</p>
						) : null}
					</div>
					<div className="Payment_container_form">
						<div className="card">
							<div className="card-card">
								<p className="smallgrey">{t("form-card-number")}</p>
								<div className="inputContainer">{cardEl}</div>
							</div>
						</div>
						<div className="Payment_submit">
							<NextButton text={t("form-next")} onClick={onSubmit} />
							{stripeError ? <p className="Payment_error">{stripeError}</p> : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PaymentPage
