import { useTranslation } from "react-i18next"
import ReactPhoneNumberInput from "react-phone-number-input/input"

export interface LabelPhoneProps {
	id: string
	label: string
	placeholder: string
	onChange: (val: string) => void
	value?: string
	disabled?: boolean
	error?: string
	errRequired?: boolean
}

const LabelPhone: React.FC<LabelPhoneProps> = (props) => {
	const { t } = useTranslation()

	const onChange = (phone: string) => {
		props.onChange(phone || "")
	}

	const error = props.errRequired ? t("err-required") : props.error ? props.error : ""

	return (
		<div className="labelinput">
			<label htmlFor={props.id}>{props.label}</label>
			<ReactPhoneNumberInput
				country="US"
				placeholder={props.placeholder}
				value={props.value}
				onChange={onChange}
				disabled={props.disabled}
				className={error ? "error" : ""}
			/>
			{error ? <p className="error">{error}</p> : null}
		</div>
	)
}

export default LabelPhone
