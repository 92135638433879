import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { debounceReact } from "../../../../lib/ts/debounce"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import {
	navigateToAppHome,
	saveSignup,
	setDOBDay,
	setDOBMonth,
	setDOBYear,
	setSaveSignupError,
	setStep,
} from "../../store/store"
import GoBack from "../common/GoBack"
import LabelInputText from "../common/LabelInputText"
import NextButton from "../common/NextButton"
import { EbbNav } from "../layout/EbbNav"
import "./BirthDate.scss"

const BirthDatePage: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const saveSignupError = useAppSelector((state) => state.saveSignupError)
	const navigate = useNavigate()
	const location = useAppSelector((state) => state.location)
	const dobMonth = useAppSelector((state) => state.dobMonth)
	const dobDay = useAppSelector((state) => state.dobDay)
	const dobYear = useAppSelector((state) => state.dobYear)
	const [missing, setMissing] = useState<any>({})
	const isNextButtonDisabled = Boolean(saveSignupError)

	// If this URL is visited directly, there won't be any location loaded.
	// In that case, redirect to Home.
	useEffect(() => {
		if (!location) return navigateToAppHome()
	}, [])

	useEffect(() => {
		dispatch(setStep(3))
	}, [])

	const debouncedSave = debounceReact(useState<NodeJS.Timeout>(), () => {
		dispatch(saveSignup as any)
	})

	const onDayChange = (val: string) => {
		dispatch(setDOBDay(Number(val)))
		debouncedSave()
	}

	const onMonthChange = (val: string) => {
		dispatch(setDOBMonth(Number(val)))
		debouncedSave()
	}

	const onYearChange = (val: string) => {
		dispatch(setDOBYear(Number(val)))
		debouncedSave()
	}

	const onSubmit = () => {
		const missing: any = {}
		if (!dobDay) {
			missing.day = true
		}
		if (!dobMonth) {
			missing.month = true
		}
		if (!dobYear) {
			missing.year = true
		}
		if (Object.keys(missing).length > 0) {
			setMissing(missing)
			return
		}
		if (!saveSignupError) {
			navigate("/ssn")
		}
	}

	const onGoBack = () => {
		dispatch(setSaveSignupError(null))
		navigate("/apartment")
	}

	return (
		<div className="BirthDate">
			<EbbNav />
			<div className="BirthDate_inner section">
				<GoBack onClick={onGoBack} />
				<div className="BirthDate_container">
					<div className="BirthDate_container_info">
						<h1>{t("birth-date")}</h1>
						<p>{t("birth-date-info")}</p>
					</div>
					<div className="BirthDate_container_form">
						<div className="BirthDate_container_form_fields">
							<LabelInputText
								id="birthdate-month"
								label={t("form-month")}
								placeholder="MM"
								type="text"
								pattern="\d*"
								max={12}
								min={1}
								maxlength={2}
								value={dobMonth ? String(dobMonth) : ""}
								onChange={onMonthChange}
								errRequired={missing.month}
								error={saveSignupError?.dobMonth}
							/>
							<LabelInputText
								id="birthdate-day"
								label={t("form-day")}
								placeholder="DD"
								type="text"
								pattern="\d*"
								maxlength={2}
								max={31}
								min={1}
								value={dobDay ? String(dobDay) : ""}
								error={saveSignupError?.dobDay}
								onChange={onDayChange}
								errRequired={missing.day}
							/>
							<LabelInputText
								id="birthdate-year"
								label={t("form-year")}
								placeholder="YYYY"
								type="text"
								pattern="\d*"
								maxlength={4}
								max={2003}
								min={1900}
								value={dobYear ? String(dobYear) : ""}
								onChange={onYearChange}
								errRequired={missing.year}
								error={saveSignupError?.dobYear}
							/>
						</div>
						<div className="BirthDate_submit">
							<NextButton text={t("form-next")} onClick={onSubmit} disabled={isNextButtonDisabled} />
						</div>
						<p className="error">{typeof saveSignupError === "string" && saveSignupError}</p>
					</div>
				</div>
			</div>
		</div>
	)
}
export default BirthDatePage
